import { store } from '../../store/store.js';
import anime from 'animejs/lib/anime.es.js';

export default class BaseAnime{

    //----------------------
    // Initialise functions
    //----------------------

	constructor(){
        this.onscrollAnimate();
	}

    //------------------------------------
    // Handle animation for functionality
    //------------------------------------

    onscrollAnimate(){
        $(document).ready(function(){
            anime({
                targets: '.portal-banner',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 1500
            })
            anime({
                targets: '.breadcrumb',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 1500
            })
            anime({
                targets: '.intro-title',
                opacity: [0,1],
                easing: 'easeInOutQuint',
                duration: 2000
            })
            anime({
                targets: '.portal__main',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 3000
            })
            anime({
                targets: '.portal__intro h3',
                translateY: [20, 0],
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 2000
            })
            anime({
                targets: '.portal__intro p',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 2500
            })
            anime({
                targets: '.portal__intro div',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 2500
            })
            anime({
                targets: '.cards',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 2000
            })
        })
    }
}
