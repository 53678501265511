import InitFormValidation from './form-validation.js'
import InitFormCounter from './form-counter.js'
import InitFormDropdown from './form-dropdown.js'
import InitFormInput from './form-input.js'
import InitSlider from './form-slider.js'
import { store } from '../../store/store.js'
import { debounce } from '../../base/helpers.js'

/**
 * Initforms
 */
export default class Initforms {
  constructor() {
    /**
     * Initalise Class Cache
     * @type {obj}
     */
    this.cache = {
      fieldWrapper: $('.sq-form-question'),
      squizForms: $("form[id*='form_']"),
      htmlForms: $('.form'),
      windowWidth: $(window).width(),
    }

    /**
     * Initalise Class Functions
     */

    this.setupValidation()
  }

  setupMarketo() {
    let marketoForm = $('[id^="mktoForm_"]')
    if ($('[id^="mktoForm_"]').length === 0) return

    this.handleWindowResize()

    MktoForms2.whenReady(
      function (form) {
        let validation = new InitFormValidation(marketoForm)
        this.setupMarketoFields(marketoForm, form, validation)

        let _this = this

        marketoForm.on(
          'change',
          'input[type=text], input[type=checkbox], input[type=radio]',
          function () {
            //find uninitialised and initialise

            //manually set the dropdown so that marketo can add any dynamic fields

            if ($(this).closest('fieldset').find('select').length > 0) {
              let selectID = $(this).closest('fieldset').find('select').attr('id')
              let selectedVal = $(this).closest('fieldset').find('select').val()

              let setValues = {}
              setValues[selectID] = selectedVal
              form.setValues(setValues)
            }

            marketoForm
              .find('input, select')
              .not('input[type="hidden"]')
              .each(function () {
                if (!$(this).hasClass(store.defaultClasses.initialise)) {
                  _this.setupMarketoField($(this).closest('.mktoFormRow'), validation)
                }
              })
          }
        )

        form.onValidate(function () {
          form.submittable(false)
          validation.isValid(function (valid) {
            console.log('Form validity:', valid)
            if (valid) form.submittable(true)
            else form.submittable(false)
          })

          return false
        })

        form.onSuccess(
          function () {
            if (marketoForm.next('p.success-message').length > 0) {
              marketoForm
                .slideUp('fast', function () {})
                .next('p.success-message')
                .fadeIn()
            }

            // return false
          }.bind(this)
        )
      }.bind(this)
    )
  }

  handleWidthResize() {
    if (!$('.success-message').is(':visible') && $('[id^="mktoForm_"]').is(':visible')) {
      let marketoForm = $('[id^="mktoForm_"]')
      marketoForm.removeAttr('style')
      marketoForm.find('[style]').each(function () {
        $(this).removeAttr('style')
      })
    }
  }

  /**
   * @property {Function} handleWindowResize - calls functions on resize
   * @returns void
   */
  handleWindowResize() {
    store.cache.$window.on(
      'resize',
      function () {
        if (this.cache.windowWidth == $(window).width()) return
        this.cache.windowWidth = $(window).width()

        debounce(this.handleWidthResize(), 100, true)
      }.bind(this)
    )
  }

  setUpTwoCol() {
    //dom manipulation here
    $('.mktoFormRow').each(function () {
      if (
        $(this).find('.mktoFieldDescriptor').length === 1 &&
        $(this).find('.mktoPlaceholder').length === 1
      ) {
        $(this).wrapInner('<div class="grid grid--nrg"></div>')
        $(this).find('.mktoFieldDescriptor').wrap('<div class="grid__col--12"></div>')
        $(this).find('.mktoPlaceholder').wrap('<div class="grid__col--12 grid__col-md--6"></div>')
      } else if ($(this).find('.mktoFieldDescriptor').length === 1) {
        $(this).wrapInner('<div class="grid grid--nrg"></div>')
        $(this)
          .find('.mktoFieldDescriptor')
          .wrap('<div class="grid__col--12 grid__col-md--6"></div>')
      }

      if ($(this).find('.mktoFieldDescriptor').length === 2) {
        $(this).wrapInner('<div class="grid grid--nrg"></div>')
        $(this)
          .find('.mktoFieldDescriptor')
          .wrap('<div class="grid__col--12 grid__col-md--6"></div>')
      }
    })
    $('.mktoButtonRow').each(function () {
      $(this).wrapInner('<div class="grid grid--nrg"></div>')
      $(this).find('.mktoButtonWrap').wrap('<div class="grid__col--12 grid__col-md--6"></div>')
    })
  }

  setupMarketoFields(marketoForm, form, validation) {
    $('head').find('[id^="mktoForm"]').remove()
    marketoForm.find('button[type=submit]').addClass('button button--primary button--block')

    marketoForm.addClass('form').removeAttr('style').find('*').removeAttr('style') //remove style attributes

    //check form rows
    //		this.setUpTwoCol()

    marketoForm.find('.mktoFormRow').each(function () {
      $(this).unwrap('fieldset')
    })

    this.setupMarketoField(marketoForm, validation)
  }

  setupMarketoField(marketoForm, validation) {
    marketoForm.find('style, .mktoOffset, .mktoClear, .mktoGutter, .mktoRangeValue').remove()

    marketoForm.find('[style]').each(function () {
      $(this).removeAttr('style')
    })

    // Textarea, Input

    marketoForm
      .find('input, textarea')
      .not('input[type="checkbox"], input[type="radio"], input[type="hidden"], input[type="range"]')
      .each(function () {
        if ($(this).hasClass(store.defaultClasses.initialise)) return false
        $(this)
          .parents('.mktoFieldWrap')
          .wrapInner(`<fieldset class="${$(this).attr('id')} form__input"></fieldset>`)
        $(this).closest('fieldset').find('label').detach().appendTo($(this).parent())

        let required = $(this).siblings('label').find('.mktoAsterix')

        if (required.length > 0) {
          required.remove()
          // let text = $(this).siblings('label').text().replace(/[*:]/g,'')
          // $(this).siblings('label').text(text + '*')
        }
        if ($(this).hasClass('mktoRequired')) {
          $(this).prop('required', true)
        }

        $(this).hasClass('mktoRequired')
          ? $(this).prop('required', true)
          : $(this).prop('required', false)

        new InitFormInput(`.${$(this).attr('id')}`)
        $(this).addClass(store.defaultClasses.initialise)
      })

    // Select

    marketoForm.find('select').each(function () {
      if ($(this).hasClass(store.defaultClasses.initialise)) return

      $(this).find('option:first-child').data('type', 'placeholder').prop('disabled', true)
      $(this)
        .parents('.mktoFieldWrap')
        .wrapInner(`<fieldset class=" form__input dropdown ${$(this).attr('id')}"></fieldset>`)
      $(this).addClass('dropdown__select') //.unwrap('.mktoFieldWrap').unwrap('.mktoFieldDescriptor').unwrap('.mktoFormRow')
      $(this).siblings('label').addClass('dropdown__label').detach().insertAfter($(this))

      let mktoAsterix = $(this).siblings('label').find('.mktoAsterix')

      if (mktoAsterix.length > 0) {
        mktoAsterix.remove()
        // $(this).prop('required', true)
      }

      $(this).hasClass('mktoRequired')
        ? $(this).prop('required', true)
        : $(this).prop('required', false)

      new InitFormDropdown(`.${$(this).attr('id')}`)
      new InitFormInput(`.${$(this).attr('id')}`)
      $(this).addClass(store.defaultClasses.initialise)
    })

    // Radio

    marketoForm.find('.mktoRadioList').each(function () {
      if ($(this).find('input[type=radio]').hasClass(store.defaultClasses.initialise)) return false

      let radioList = $(this)

      radioList
        .parents('.mktoFieldWrap')
        .wrapInner(`<fieldset class="${$(this).attr('id')}"></fieldset>`)

      let mktoAsterix = radioList.parents('.mktoFieldWrap').find('label').find('.mktoAsterix')
      if (mktoAsterix.length > 0) {
        mktoAsterix.remove()
        $(this).find('input').prop('required', true)
      }

      let label = radioList.parents('.mktoFieldWrap').children('label')

      label.after(`<legend for="${label.attr('for')}">${label.text()}</legend>`)
      label.remove()

      $(this).append('<ul class="form__list"></ul>')

      $(this)
        .find('input')
        .each(function () {
          let label = $(this).next().detach()
          let input = $(this).detach()
          radioList.find('ul.form__list').append('<li class="form__radio"></li>')
          input.appendTo(radioList.find('li:last-child'))
          label.appendTo(radioList.find('li:last-child'))
        })
      new InitFormInput(`.${$(this).attr('id')}`)
      $(this).find('input[type=radio]').addClass(store.defaultClasses.initialise)
    })

    // Checkbox
    marketoForm.find('.mktoCheckboxList').each(function () {
      if ($(this).find('input[type=checkbox]').hasClass(store.defaultClasses.initialise))
        return false

      let radioList = $(this)

      radioList
        .parents('.mktoFieldWrap')
        .wrapInner(`<fieldset class="${$(this).attr('id')}"></fieldset>`)

      let mktoAsterix = radioList.parents('.mktoFieldWrap').find('label').find('.mktoAsterix')

      if (mktoAsterix.length > 0) {
        mktoAsterix.remove()
      }

      // $(this).hasClass('mktoRequired')
      //   ? $(this).prop('required', true)
      //   : $(this).prop('required', false)

      let label = radioList.parents('.mktoFieldWrap').children('label')

      label.after(`<legend for="${label.attr('for')}">${label.text()}</legend>`)
      label.remove()

      $(this).append('<ul class="form__list"></ul>')
      $(this)
        .find('input')
        .each(function () {
          let label = $(this).next().detach()
          let input = $(this).detach()
          radioList.find('ul.form__list').append('<li class="form__checkbox"></li>')
          input.appendTo(radioList.find('li:last-child'))
          label.appendTo(radioList.find('li:last-child'))
        })
      new InitFormInput(`.${$(this).attr('id')}`)
      $(this).find('input[type=checkbox]').addClass(store.defaultClasses.initialise)
    })

    // Slider

    marketoForm.find('input[type=range]').each(function () {
      if ($(this).hasClass(store.defaultClasses.initialise)) return

      $(this)
        .parents('.mktoFieldWrap')
        .wrapInner(`<fieldset class="${$(this).attr('id')}"><div class="slider"></div></fieldset>`)
      $(this)
        .unwrap('.mktoLogicalField')
        .unwrap('.mktoFieldWrap')
        .unwrap('.mktoFieldDescriptor')
        .unwrap('.mktoFormRow')
      $(this).addClass('slider__input').wrap('<div class="slider__wrap"></div>')

      let mktoAsterix = $(this).closest('fieldset').find('label .mktoAsterix')

      $(this).closest('fieldset').find('label').addClass('slider__label')
      if (mktoAsterix.length > 0) {
        mktoAsterix.remove()
        $(this).closest('fieldset').find('input').prop('required', true)
      }
      $(this).addClass(store.defaultClasses.initialise)
    })

    // Phone

    marketoForm.find('input[type=tel]').each(function () {
      validation.setUpPhoneField($(this))
      new InitFormInput(`.${$(this).attr('id')}`)
    })

    //run GEM validation
    new InitFormCounter('[id^="mktoForm"]')
    new InitSlider('[id^="mktoForm"]')
  }

  setupSquizFields(squizForm) {
    let validation = new InitFormValidation(squizForm)

    this.cache.squizForms.addClass('form')

    this.cache.fieldWrapper.each(function () {
      if ($(this).find('input[type=hidden]').length > 0) {
        $(this).addClass('visually-hidden')
        return false
      }

      //add fieldset
      if ($(this).find('fieldset').length === 0) {
        $(this).wrapInner('<fieldset class="form__row form__input"></fieldset>')
      } else {
        $(this).find('fieldset').addClass('form__row')
      }

      //required
      if ($(this).find('.sq-form-required-field').length > 0) {
        $(this).find('fieldset').addClass('js-validation js-required-validation')
        $(this).find('input, select, textarea').prop('required', true)
        $(this).find('.sq-form-required-field').replaceWith('*')
      }

      if ($(this).find('.sq-form-question-note').length > 0) {
        $(this).find('.sq-form-question-note').detach().insertBefore($(this).find('fieldset'))
      }

      //select
      if ($(this).find('select').length > 0) {
        $(this).find('select').addClass('dropdown__select').unwrap()
        $(this).find('fieldset').addClass('dropdown')
        $(this)
          .find('label')
          .addClass('dropdown__label')
          .detach()
          .appendTo($(this).find('fieldset'))
      }

      //textarea, input
      if (
        $(this)
          .find('input, textarea')
          .not(
            'input[type="checkbox"], input[type="radio"], input[type="hidden"], input[type="tel"]'
          ).length > 0
      ) {
        $(this).find('label').detach().appendTo($(this).find('fieldset'))
        $(this).find('input').unwrap()
      }

      //radio
      if ($(this).find('input[type="radio"]').length > 0) {
        $(this)
          .find('.sq-form-question-answer')
          .addClass('form__radio')
          .find('ul')
          .addClass('form__list')
          .find('li')
          .addClass('form__radio')
      }

      //checkbox
      if ($(this).find('input[type="checkbox"]').length > 0) {
        $(this)
          .find('.sq-form-question-answer')
          .addClass('form__checkbox')
          .find('ul')
          .addClass('form__list')
          .find('li')
          .addClass('form__checkbox')
      }

      //phone fields
      $(this)
        .find('input[type="tel"]')
        .each(function () {
          validation.setUpPhoneField($(this))
        })
    })

    // $('.sq-form-question fieldset').unwrap('.sq-form-question-answer').unwrap('.sq-form-question') //to remove the two divs surrounding
    $('.sq-form-question fieldset').unwrap().unwrap() //to remove the two divs surrounding

    //submit button
    $('.sq-form-control')
      .addClass('form__row')
      .find('input[type=submit]')
      .addClass('button button--primary button--block')

    //listener for form submit
    this.cache.squizForms.submit(function (e) {
      console.log('Submitting form...')
      if ($(this).find('input[type=file]').length > 0) {
        $(this)
          .find('input[type=submit]')
          .addClass('visually-hidden')
          .after('<p class="text--center">Form submitting...</p>')

        return
      }

      e.preventDefault()

      $(this).find('input[type=submit]').attr('disabled', 'disabled')

      var form = $(this)
      var url = form.attr('action')

      if (!url.includes('_nocache')) url = url + '/_nocache?SQ_ASSET_CONTENTS_RAW'

      var form_data = form.serializeArray()
      form_data.push({ name: `${form.attr('id')}_submit`, value: 'Send' })

      $.ajax({
        type: 'POST',
        url: url,
        data: form_data, // serializes the form's elements.
        success: function (data) {
          form.slideUp().next('p.success-message').fadeIn()
        },
      })
    })

    $('#form-iframe', parent.document).height(this.cache.squizForms.height() + 5)
  }

  setupValidation() {
    //HTML forms
    this.cache.htmlForms.each(function () {
      let validation = new InitFormValidation($(this))

      new InitFormDropdown()
      new InitFormInput()
    })

    //Squiz
    if (this.cache.squizForms.length > 0) {
      this.setupSquizFields(this.cache.squizForms)

      new InitFormDropdown()
      new InitFormInput()
    }

    //marketo
    this.setupMarketo()
  }

  getFormSuccessMessage(form) {
    return form.next('p.success-message').text()
  }
}

/** IE Fixes **/
;(function () {
  /** IE Fix for foreach **/
  if (typeof NodeList.prototype.forEach === 'function') return false
  NodeList.prototype.forEach = Array.prototype.forEach

  //** IE fix for includes **/
  if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
      if (typeof start !== 'number') {
        start = 0
      }

      if (start + search.length > this.length) {
        return false
      } else {
        return this.indexOf(search, start) !== -1
      }
    }
  }
})()
