import { store } from '../../store/store.js';

import InitAlert from '../alert.js';
import InitAccordion from '../accordion.js';
import InitModal from '../modal.js';
import InitTab from '../tab.js';
import InitTable from '../table.js';
import InitTag from '../tag.js';
import InitTemplates from '../../base/templates';
import InitTooltip from '../tooltip.js';
import InitToggle from '../toggle.js';
import InitVideo from '../video.js';
import InitPortalTab from '../portal/portal-tab.js';
import InitStickyTab from './tab.js';
import InitImageGallery from '../gallery.js';
import InitVideoGallery from '../videogallery.js';

import { parse } from 'url';

/**
 * InitAlert
 */
export default class InitPortalNav  {

	constructor(obj) {

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			components: obj,
			sticky: 'is-sticky',
			fixed: 'is-fixed',
			$metaTitle: $('meta[name=title]'),
			$metaDescription: $('meta[name=description]'),
			$sidenav: $('.sidenav__portal'),
			$wrapper: $('.sidenav__portal .sidenav__portal-wrapper'),
			$list: $('.sidenav__portal .sidenav__portal-list'),
			$header: $('.header__portal'),
			$footer: $('.footer__portal'),
			$content: $('.js-portal-main-content'),
			$relatedPages: $('.related-pages'),
			$video:	$('.video'),
			anchorId: 'section-',
			foldTop: 'fold-top',
			foldBottom: 'fold-bottom',
			scrolling: false,
			originalOffset: 0,
			padding: 50,
			updatingHeight: false,
			updatingWidth: false,
			updatingOffset: false,
			updatingContent: false
		};

		// If sidenav exists for the page
		if (this.cache.$sidenav.length > 0) {
			/**
			 * Initalise Class Functions
			 */
			this.setHooks();
			this.updateOffset();
			// this.detectFold();
			this.setPosition();

			/**
			 * Unset max height
			 */
			this.cache.$wrapper.css('max-height', 'auto');

			/**
			 * Update Anchors on page load
			 */
			var loadAnchors = $($(this.cache.$content).find('[id^="section-"]')),
				activeItem	= $(this.cache.$list.find('.is-active'));
			this.updateAnchors(activeItem, loadAnchors);
		}
	}

	/**
	 * @property {Function} setHooks - set the event hooks for the update functions
	 * @returns void
	 */
	setHooks() {
		/*
			Scroll Events
		*/
		store.cache.$window.on('scroll', function () {
			this.setPosition();
		}.bind(this));
		// this.cache.$list.children('ul').on('scroll', function(){
		// 	this.detectFold();
		// }.bind(this));

		/*
			Resize Events
		*/
		store.cache.$window.on('resize', function () {
			this.updateOffset();
			this.setPosition();
		}.bind(this));

		/*
			Click Event
		*/
		this.cache.$sidenav.find('.sidenav__portal-list > ul > li > a').on('click', this.handleClick.bind(this));

		/*
			Keyboard Events
		*/
		this.handleKeyboardUser();
	}

	/**
	 * @property {Function} setPosition - handles updating the state of the sidenav element
	 * @returns void
	 */
	setPosition() {
		if (!this.cache.scrolling) {
			this.cache.scrolling = true;

			var isFixed = false,
				isSticky = false;

			// If scrolled past the header, nav becomes sticky
			if (store.cache.$window.scrollTop() >= this.cache.originalOffset-this.cache.padding-this.cache.$header.innerHeight()) {
				isSticky = true;
			}

			// If footer is close enough to the sidenav, fix sidenav to the footer
			const 	windowTop 		= store.cache.$window.scrollTop(),
					windowHeight 	= store.cache.$window.innerHeight(),
					headerHeight 	= this.cache.$header.innerHeight(),
					padding 		= this.cache.padding,
					wrapperTop 		= this.cache.$wrapper.offset().top,
					wrapperHeight 	= this.cache.$wrapper.innerHeight(),
					footerTop 		= this.cache.$footer.length > 0 ? this.cache.$footer.offset().top : 0,
					footerHeight 	= this.cache.$footer.length > 0 ? this.cache.$footer.innerHeight() : 0;

			if (this.cache.$footer.length > 0
				&& windowTop + headerHeight + padding >= wrapperTop
				&& wrapperTop + wrapperHeight >= footerTop
				|| this.cache.$footer.length > 0
				&& windowTop + windowHeight - padding >= footerTop + footerHeight) {
				isFixed = true;
			}

			// If past the header, add sticky
			if (isSticky) {
				this.cache.$sidenav.addClass(this.cache.sticky);
				this.cache.$wrapper.css('top',this.cache.padding+this.cache.$header.innerHeight()+'px');
			} else {
				this.cache.$sidenav.removeClass(this.cache.sticky);
				this.cache.$wrapper.css('top','auto');
			}

			// If close to the bottom, override
			if (isFixed) {
				this.cache.$sidenav.addClass(this.cache.fixed);
				this.cache.$wrapper.css('top','');
			} else {
				this.cache.$sidenav.removeClass(this.cache.fixed);
			}

			// Update height and width if needed
			this.updateHeight();
			this.updateWidth();

			// Only allow this to run again after 10ms
			setTimeout(function () {
				this.cache.scrolling = false;
			}.bind(this), 10);
		}
	}

	/**
	 * @property {Function} updateOffset - handles updating the offset value for the sidenav list
	 * @returns void
	 */
	updateOffset() {
		if (!this.cache.updatingOffset) {
			this.cache.updatingOffset = true;

			this.cache.$sidenav.removeClass(`${this.cache.sticky} ${this.cache.fixed}`);
			this.cache.$wrapper.css('top','auto');
			this.cache.originalOffset = this.cache.$wrapper.offset().top;

			// Reset position if needed
			this.setPosition();

			// Only allow this to run again after 10ms
			setTimeout(function () {
				this.cache.updatingOffset = false;
			}.bind(this), 10);
		}
	}

	/**
	 * @property {Function} updateWidth - handles updating the width of the fixed element
	 * @returns void
	 */
	updateWidth() {
		if (!this.cache.updatingWidth) {
			this.cache.updatingWidth = true;

			if (this.cache.$sidenav.hasClass(this.cache.fixed)) {
				// If fixed to the bottom, remove width override
				this.cache.$wrapper.css('width', '');
			} else {
				this.cache.$wrapper.css('width', this.cache.$sidenav.css('width'));
			}

			// Only allow this to run again after 10ms
			setTimeout(function () {
				this.cache.updatingWidth = false;
			}.bind(this), 10);
		}
	}

	/**
	 * @property {Function} updateHeight - handles updating the height of the fixed element
	 * @returns void
	 */
	updateHeight() {
		if (!this.cache.updatingHeight) {
			this.cache.updatingHeight = true;

			// Have wrapper take full height
			const	height	= store.cache.$window.innerHeight() - this.cache.$header.innerHeight() - this.cache.$sidenav.css('padding-top').replace('px','') - this.cache.$relatedPages.outerHeight();

			this.cache.$wrapper.css('height', height);

			// Update list height
			const 	header 	= this.cache.$wrapper.children('span').innerHeight();
			this.cache.$list.css('height', this.cache.$wrapper.innerHeight() - header);

			// Only allow this to run again after 10ms
			setTimeout(function () {
				this.cache.updatingHeight = false;
			}.bind(this), 10);
		}
	}

	/**
	 * @property {Function} handleClick - handles the click event on
	 * @returns void
	 */
	handleClick(e) {
		e.preventDefault();
        $('html,body').animate({ scrollTop: 0 }, 'smooth');
		var $target = $(e.currentTarget),
			$current = this.cache.$list.find('.is-active');

		// Remove current otherwise make new item active
		if ($current.length > 0) {
			var list = $current.find('.js-sidenav-anchor-list');
			if (list.length > 0) {
				list.slideUp('slow', function(){
					list.remove();
					$current.removeClass('is-active');
					$($target.parent()).addClass('is-active');

				}.bind(this));
			} else {
				$($target.parent()).addClass('is-active');
			}

		} else {
			$($target.parent()).addClass('is-active');
		}

		this.getContent($target);
	}

	/**
	 * @property {Function} retrieveContent - handles retrieving the content from a particular url
	 * @returns void
	 */
	getContent($target) {
		$.ajax({
			url: $target.attr('href'),
			type: 'GET',
			beforeSend: function() {
				$('.loader--spinner').show();
			}.bind(this),
			success: function(data) {
				let contentData,
				    relatedPages,
					title,
					metaTitle,
					metaDescription;
				$('.loader--spinner').hide();
				contentData = $(data).find('.js-portal-main-content').html();
				relatedPages = $(data).find('.related-pages').html();
				title = $(data).filter('title').html();
				metaTitle = $(data).filter('meta[name=title]').attr('content');
				metaDescription = $(data).filter('meta[name=description]').attr('content');
				this.updateContent($target, contentData, relatedPages, title, metaTitle, metaDescription);
				history.pushState(null, '', $target.attr('href'));
				$('ol.breadcrumb__list').replaceWith($(data).find('ol.breadcrumb__list'));
			}.bind(this)
		});
	}

	/**
	 * @property {Function} updateContent - handles replacing main content with the new page content
	 * @returns void
	 */
	updateContent(target, html, relatedPages, title, metaTitle, metaDescription) {
		if (!this.cache.updatingContent) {
			this.cache.updatingContent = true;
			this.cache.$content.fadeOut(200, function(){
				document.title = title;
				this.cache.$metaTitle.attr('content',metaTitle);
				this.cache.$metaDescription.attr('content',metaDescription);
				this.cache.$content.html(html);
				this.cache.$relatedPages.html(relatedPages);
				var anchors = $($(html).find('[id^="section-"]'));
				this.cache.$content.fadeIn({
					duration:200,
					start: function(){
						this.setPosition();
						this.updateAnchors($(target.parent()), anchors);
						this.reInit();
					}.bind(this),
					complete: function() {
						this.cache.updatingContent = false;
					}.bind(this)
				});
			}.bind(this));
		}
	}

	/**
	 * @property {Function} updateAnchors - handles updating the child list of anchors for the current page and removing other anchors
	 * @returns void
	 */
	updateAnchors($container, $anchors) {
		var $list = $('<ul class="js-sidenav-anchor-list" style="display:none;"></ul>');
		$anchors.each(function(index, elem) {
			var $anchor = $('<a href="#'+$(elem).attr('id')+'"><span>'+$(elem).text()+'</span></a>'),
				$item 	= $('<li></li>');

			/*
				Onclick event
			*/
			$anchor.on('click', function(e){
				e.preventDefault();
				e.stopPropagation;

				var $target = $('[id="'+$(e.currentTarget).attr('href').replace('#','')+'"]');
				window.scrollTo(0, $target.offset().top - this.cache.$header.innerHeight() - this.cache.padding);
                //console.log(store.cache.$window.scrollTop());
			}.bind(this));
			$list.append($item.append($anchor));
		}.bind(this));
		$container.find('.js-sidenav-anchor-list').remove();
		$container.append($list);
		if($anchors.length>1){
		$list.slideDown('slow');
		}
	}

	/**
	 * @property {Function} updateCurrent - handles updating the current page being viewed
	 * @returns void
	 */
	updateCurrent() {

	}

	/**
	 * @property {Function} detectFold - handles updating shadow classes for the list when it has been scrolled.
	 * @returns void
	 */
	detectFold() {
		const $list = $(this.cache.$list.children('ul'));
		// If list is scrolled to the top
		if ($list.scrollTop() >= 25) {
			this.cache.$list.addClass(this.cache.foldTop);
		} else {
			this.cache.$list.removeClass(this.cache.foldTop);
		}

		// If list has scrolled to the bottom
		if ($list[0].scrollHeight - ($list.scrollTop() + $list.height()) >= 25) {
			this.cache.$list.addClass(this.cache.foldBottom);
		} else {
			this.cache.$list.removeClass(this.cache.foldBottom);
		}
	}

	/**
	 * @property {Function} reInit - handles reinitialising all the components for the new content
	 * @returns void
	 */
	reInit() {
		//Component Initialisers
		new InitAlert();
		new InitAccordion();
		new InitModal();
		new InitTab();
		new InitTable();
		new InitTag();
		new InitTooltip();
		new InitToggle();
		new InitVideo();
		new InitTemplates();
		new InitPortalTab();
		new InitStickyTab();
		new InitImageGallery();
		new InitVideoGallery();
	}

	/**
	 * @property {Function} handleKeyboardUser  - Handles tab spacebar keyboard stroke - open/close
	 * @returns void
	 */
	handleKeyboardUser(){
		this.cache.$list.find('li, a').keydown(function(e){
			if (e.keyCode == 32) {
				e.preventDefault();
				$(e.target).click();
			}
		}.bind(this));
	}
}
