import { store } from '../../store/store.js';
export default class InitStickyTabs{

  // Sticky tabs exist within the GEM Portal

	constructor(){
		this.cache = {
      $sideNav: $('.sidenav__portal'),
      $header: $('.header__main'),
      $headerSticky: $('.header__main.is-sticky'),

      $tabs: $('.tab--sticky'),
      $tabsNav: $('.tab--sticky .tabs'),
      $tabButtons: $('.tab--sticky .tabs button'),
      $tabContent: $('.tab--sticky .content'),
      $tabItems: $('.tab--sticky .content .tab__item'),

      scrolling: false,
		};

		if (this.cache.$tabs.length > 0) {
			this.loadTabs()
      this.setListeners()
		}
	}

  loadTabs(){
    let _this = this
		// Adds active class to first tab
    this.cache.$tabs.each(function(){
      $(this).find('.tabs button').first().addClass('is-active')
    })
		// Creates data attributes for each tab and respective content item
    this.cache.$tabButtons.each(function(index){
      let dataValue = $(this).text().replace(/\s+/g, '-').toLowerCase()
      $(this).attr('data-value', dataValue)
      _this.cache.$tabContent.find('.tab__item:eq('+index+')').attr('data-value', dataValue)
    })
		// Adds active class to first item by finding the initial active tab
    this.cache.$tabItems.each(function(){
      let $self = $(this)
      _this.cache.$tabButtons.each(function(){
        if($(this).hasClass('is-active') && $(this).attr('data-value') == $self.attr('data-value'))
          $self.show()
      })
    })
  }

  setListeners(){
    let _this = this
    let offsets = _this.getOffsets()
    _this.cache.$tabButtons.on('click', function(){
      let dataValue = $(this).attr('data-value')
      _this.handleButtonClick($(this), dataValue)
    })
    $(window).on('scroll resize', function(){
      let offsets = _this.getOffsets()
      _this.toggleSticky(offsets.tabOffsetTop, offsets.contentOffsetLeft, offsets.headerOffsetBottom)
    })
  }

	// Gets offsets to handle sticky state and when resizing window

  getOffsets(){
    let tabOffsetTop = this.cache.$tabs.offset().top
    let headerOffsetBottom = this.cache.$header.offset().top + this.cache.$header.outerHeight(true)
    let sideNavOffsetRight = this.cache.$sideNav.offset().left + this.cache.$sideNav.outerWidth(true)
    let contentOffsetLeft = this.cache.$tabContent.offset().left
    return {
      tabOffsetTop: tabOffsetTop,
      headerOffsetBottom: headerOffsetBottom,
      sideNavOffsetRight: sideNavOffsetRight,
      contentOffsetLeft: contentOffsetLeft
    }
  }

	// Toggles active class and visible item

  handleButtonClick($self, dataValue){
    let _this = this
    this.cache.$tabButtons.not($self).removeClass('is-active')
    $self.addClass('is-active')
    this.cache.$tabItems.each(function(){
      if($(this).attr('data-value') == dataValue){
        $(this).fadeIn()
      } else {
        $(this).hide()
      }
    })
  }

	// Checks if header is sticky to determine top offset

  toggleSticky(tabOffsetTop, contentOffsetLeft, headerOffsetBottom){
    if(!this.cache.scrolling){
      this.cache.scrolling = true;
      if(this.cache.$header.attr('data-cloned') == 'true' && $(window).scrollTop() + headerOffsetBottom > tabOffsetTop){
        this.cache.$tabsNav.addClass('is-sticky')
        this.cache.$tabsNav.css('left', contentOffsetLeft + 'px')
        this.cache.$tabsNav.css('top', headerOffsetBottom + 'px')
      } else if(this.cache.$header.attr('data-cloned') == 'false' && $(window).scrollTop() > tabOffsetTop){
        this.cache.$tabsNav.addClass('is-sticky')
        this.cache.$tabsNav.css('left', contentOffsetLeft + 'px')
        this.cache.$tabsNav.css('top', 0)
      } else {
        this.cache.$tabsNav.removeClass('is-sticky')
        this.cache.$tabsNav.css('left', 0)
        this.cache.$tabsNav.css('top', 0)
      }
      setTimeout(function () {
        this.cache.scrolling = false;
      }.bind(this), 100);
    }
  }
}
