var EXP = (function (lib) {
    lib.MethodEnum = {
        GET: 'GET',
        POST: 'POST'
    };
    lib.DataTypeEnum = {
        XML: 'xml',
        JSON: 'json'
    };
    return lib;
} (EXP || {}));

EXP.DQ = (function (win, dq, $) {
    dq.UrlEnum = {
        ADDRESS: 0,
        EMAIL: 1,
        MOBILE: 2,
        GEO: 3
    };

    dq.TokenEnum = {
        ADDRESS: 0,
        EMAIL: 1,
        MOBILE: 2
    };
    dq.Urls = {};
    dq.Tokens = {};
    dq.addUrl = function (service, url) {
        for (var enumVal in EXP.DQ.UrlEnum) {
            if (EXP.DQ.UrlEnum.hasOwnProperty(enumVal)) {
                if (service === EXP.DQ.UrlEnum[enumVal]) {
                    EXP.DQ.Urls[service] = url;
                    return;
                }
            }
        }
        throw "Not an EXP DQ service: " + service;
    };

    dq.addToken = function (service, token) {
        for (var enumVal in EXP.DQ.TokenEnum) {
            if (EXP.DQ.TokenEnum.hasOwnProperty(enumVal)) {
                if (service === EXP.DQ.TokenEnum[enumVal]) {
                    EXP.DQ.Tokens[service] = token;
                    return;
                }
            }
        }
        throw "Not an EXP DQ token: " + token;
    };
    dq.reset = function () {
        dq.Urls = {};
        dq.Tokens = {};
    };

    dq.callWebService = function (method, url, headers, content, datatype) {
        if (!EXP.DQ.Urls[url]) {
            throw "Unable to search, missing url: " + url;
        }

        var self = this;
        return $.ajax({
            context: self,
            type: EXP.MethodEnum[method],
            headers: headers,
            url: EXP.DQ.Urls[url],
            data: content,
            dataType: EXP.DataTypeEnum[datatype]
        });
    };

    /*	This work is licensed under Creative Commons GNU LGPL License.

        License: http://creativecommons.org/licenses/LGPL/2.1/
       Version: 0.9
        Author:  Stefan Goessner/2006
        Web:     http://goessner.net/ 
    */
    function xml2json(xml, tab) {
        var X = {
            toObj: function (xml) {
                var o = {};
                if (xml.nodeType === 1) {   // element node ..
                    if (xml.attributes.length) { // element with attributes  ..
                        for (var i = 0; i < xml.attributes.length; i++) {
                            o["@" + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
                        }
                    }

                    if (xml.firstChild) { // element has child nodes ..
                        var textChild = 0, cdataChild = 0, hasElementChild = false;
                        var n;
                        for (n = xml.firstChild; n; n = n.nextSibling) {
                            if (n.nodeType === 1) {
                                hasElementChild = true;
                            }
                            else if (n.nodeType === 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
                                // non-whitespace text
                                textChild++;
                            }
                            else if (n.nodeType === 4) {
                                // cdata section node
                                cdataChild++;
                            }
                        }

                        if (hasElementChild) {
                            if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                                X.removeWhite(xml);
                                for (n = xml.firstChild; n; n = n.nextSibling) {
                                    if (n.nodeType === 3) {
                                        // text node
                                        o["#text"] = X.escape(n.nodeValue);
                                    } else if (n.nodeType === 4) {
                                        // cdata node 
                                        o["#cdata"] = X.escape(n.nodeValue);
                                    } else if (o[n.nodeName]) {
                                        // multiple occurence of element ..
                                        if (o[n.nodeName] instanceof Array) {
                                            o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                                        } else {
                                            o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                                        }
                                    } else {
                                        // first occurence of element..
                                        o[n.nodeName] = X.toObj(n);
                                    }
                                }
                            }
                            else {
                                if (!xml.attributes.length) {
                                    // mixed content
                                    o = X.escape(X.innerXml(xml));
                                } else {
                                    o["#text"] = X.escape(X.innerXml(xml));
                                }
                            }
                        }
                        else if (textChild) {
                            // pure text
                            if (!xml.attributes.length) {
                                o = X.escape(X.innerXml(xml));
                            } else {
                                o["#text"] = X.escape(X.innerXml(xml));
                            }
                        }
                        else if (cdataChild) {
                            // cdata
                            if (cdataChild > 1) {
                                o = X.escape(X.innerXml(xml));
                            } else {
                                for (n = xml.firstChild; n; n = n.nextSibling) {
                                    o["#cdata"] = X.escape(n.nodeValue);
                                }
                            }
                        }
                    }
                    if (!xml.attributes.length && !xml.firstChild) {
                        o = null;
                    }
                } else if (xml.nodeType === 9) {
                    // document.node
                    o = X.toObj(xml.documentElement);
                } else {
                    console.error("unhandled node type: " + xml.nodeType);
                }

                return o;
            },
            toJson: function (o, name, ind) {
                var json = name ? ("\"" + name + "\"") : "";
                if (o instanceof Array) {
                    for (var i = 0, n = o.length; i < n; i++) {
                        o[i] = X.toJson(o[i], "", ind + "\t");
                    }
                    json += (name ? ":[" : "[") + (o.length > 1 ? ("\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind) : o.join("")) + "]";
                } else if (o == null) {
                    json += (name && ":") + "null";
                } else if (typeof (o) === "object") {
                    var arr = [];
                    for (var m in o) {
                        arr[arr.length] = X.toJson(o[m], m, ind + "\t");
                    }
                    json += (name ? ":{" : "{") + (arr.length > 1 ? ("\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind) : arr.join("")) + "}";
                } else if (typeof (o) === "string") {
                    json += (name && ":") + "\"" + o.toString() + "\"";
                } else {
                    json += (name && ":") + o.toString();
                }
                return json;
            },
            innerXml: function (node) {
                var s = "";
                if ("innerHTML" in node) {
                    s = node.innerHTML;
                } else {
                    var asXml = function (n) {
                        var s = "";
                        if (n.nodeType === 1) {
                            s += "<" + n.nodeName;
                            for (var i = 0; i < n.attributes.length; i++) {
                                s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue || "").toString() + "\"";
                            }
                            if (n.firstChild) {
                                s += ">";
                                for (var c = n.firstChild; c; c = c.nextSibling) {
                                    s += asXml(c);
                                }
                                s += "</" + n.nodeName + ">";
                            } else {
                                s += "/>";
                            }
                        } else if (n.nodeType === 3) {
                            s += n.nodeValue;
                        } else if (n.nodeType === 4) {
                            s += "<![CDATA[" + n.nodeValue + "]]>";
                        }

                        return s;
                    };
                    for (var c = node.firstChild; c; c = c.nextSibling) {
                        s += asXml(c);
                    }
                }
                return s;
            },
            escape: function (txt) {
                return txt.replace(/[\\]/g, "\\\\")
                    .replace(/[\"]/g, '\\"')
                    .replace(/[\n]/g, '\\n')
                    .replace(/[\r]/g, '\\r');
            },
            removeWhite: function (e) {
                e.normalize();
                for (var n = e.firstChild; n;) {
                    if (n.nodeType === 3) {
                        // text node
                        if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
                            // pure whitespace text node 
                            var nxt = n.nextSibling;
                            e.removeChild(n);
                            n = nxt;
                        } else {
                            n = n.nextSibling;
                        }
                    } else if (n.nodeType === 1) {
                        // element node
                        X.removeWhite(n);
                        n = n.nextSibling;
                    } else {
                        // any other node
                        n = n.nextSibling;
                    }
                }
                return e;
            }
        };
        if (xml.nodeType === 9) {
            // document node
            xml = xml.documentElement;
        }

        var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
        return "{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
    }

    dq.convertXml2Json = function (content) {
        if (typeof xml2json !== 'function') {
            throw 'xml2json.js not found - load this script before EDQ scripts.';
        }

        return xml2json(content, '');
    };

    // Initialize logging methods to ensure no exceptions when using logging.
    dq.logger = win.console || {};
    dq.logger.log = dq.logger.log || function (message) { };
    dq.logger.error = dq.logger.error || function (error) { };

    return dq;
} (window, EXP.DQ || {}, jQuery));


var EXP = EXP || {};
EXP.DQ = EXP.DQ || {};

EXP.DQ.Address = (function (lib, dq, $, undefined) {

    /**
     * A SOAP address search client.
     */
    lib.SoapClient = function (options) {

        var _self = this;

        _self.options = {
            overridePrefix: 'Use entered: ',
            overrideSuffix: ''
        };
        options = options === undefined ? {} : options;
        $.extend(_self.options, options);


        /**
         * Search for address.
         * @param {string} query Text containing address to be searched for.
         * @param {string} dataset Dataset or country to search for.
         * @param {string} layout Layout name to be used. Defaults to empty string.
         * @param {string} engine Engine name to be used. Defaults to Intuitive engine.
         */
        this.search = function (query, dataset, layout, engine) {
            return lib.promise.call(_self, function (dfd) {
                // Engine might change for each search, thus not defined in constructor.
                // Default engine is intuitive.
                engine = engine === undefined || engine === null ?
                    new lib.Engine({ engineType: lib.EngineEnum.INTUITIVE }) :
                new lib.Engine({ engineType: lib.EngineEnum[engine.toUpperCase()] });

                layout = layout === undefined || layout === null ? null : new lib.Layout(layout, layout);

                dq.addUrl(dq.UrlEnum.ADDRESS, _self.options.soapUrl);
                dq.addToken(dq.TokenEnum.ADDRESS, _self.options.token);

                _self.setLayout(layout);
                _self.setEngine(engine);
                _self.setDataSet(new lib.DataSet(dataset, dataset));
                lib.rejectIfThrow.call(_self, dfd, function (dfd) {
                    dq.addUrl(dq.UrlEnum.ADDRESS, _self.options.soapUrl);
                    dq.addToken(dq.TokenEnum.ADDRESS, _self.options.token);
                    _self.setEngine(engine);
                    _self.setDataSet(new lib.DataSet(dataset, dataset));
                    _self.doSearch(query)
                        .done(function (result) { dfd.resolve(result); })
                        .fail(function (e) { dfd.reject(e); });
                });
            });
        };

        /**
         * Get final address using a moniker a.k.a format address.
         * @param {string} moniker Moniker returned in the Search step, which is used to identify address to be formatted.
         * @param {string} dataset Dataset or country to search for.
         * @param {string} layout Layout used to format the address. Address components are defined in layout.
         * @param {string} engine Engine name to be used. Defaults to Intuitive engine.
         */
        this.format = function (moniker, dataset, layout, engine) {
            return lib.promise.call(_self, function (dfd) {
                // Engine might change for each search, thus not defined in constructor.
                // Default engine is intuitive.
                engine = engine === undefined || engine === null ?
                    new lib.Engine({ engineType: lib.EngineEnum.INTUITIVE }) :
                new lib.Engine({ engineType: lib.EngineEnum[engine.toUpperCase()] });

                dq.addUrl(dq.UrlEnum.ADDRESS, _self.options.soapUrl);
                dq.addToken(dq.TokenEnum.ADDRESS, _self.options.token);
                _self.setEngine(engine);
                _self.setDataSet(new lib.DataSet(dataset, dataset));
                lib.rejectIfThrow.call(_self, dfd, function (dfd) {
                    dq.addUrl(dq.UrlEnum.ADDRESS, _self.options.soapUrl);
                    dq.addToken(dq.TokenEnum.ADDRESS, _self.options.token);
                    _self.setEngine(engine);
                    _self.setDataSet(new lib.DataSet(dataset, dataset));
                    _self.setLayout(new dq.Address.Layout(layout, layout));
                    _self.doGetAddress(moniker)
                        .done(function (result) { dfd.resolve(result); })
                        .fail(function (e) { dfd.reject(e); });
                });
            });
        };

        var doGetDataPacket = '<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader /></soapenv:Header><soapenv:Body><ond:QAGetData Localisation="?" /></soapenv:Body></soapenv:Envelope>';
        var doGetLayoutsPacket = '<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader /></soapenv:Header><soapenv:Body><ond:QAGetLayouts Localisation="?"><ond:Country>{{country}}</ond:Country></ond:QAGetLayouts></soapenv:Body></soapenv:Envelope>';
        var doCanSearchPacket = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header></soapenv:Header><soapenv:Body><ond:QACanSearch><ond:Country>{{country}}</ond:Country><ond:Engine>{{engine}}</ond:Engine><ond:Layout>{{layout}}</ond:Layout></ond:QACanSearch></soapenv:Body></soapenv:Envelope>';
        var doSearchPacket = '<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader /></soapenv:Header><soapenv:Body><ond:QASearch><ond:Country>{{country}}</ond:Country><ond:Engine Flatten="{{flatten}}" Intensity="{{intensity}}" PromptSet="{{promptSet}}" Threshold="{{threshold}}" Timeout="{{timeout}}">{{engine}}</ond:Engine><ond:Layout>{{layout}}</ond:Layout><ond:Search>{{searchTerm}}</ond:Search></ond:QASearch></soapenv:Body></soapenv:Envelope>';
        var doGetAddressPacket = '<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader /></soapenv:Header><soapenv:Body><ond:QAGetAddress><ond:Layout>{{layout}}</ond:Layout><ond:Moniker>{{moniker}}</ond:Moniker></ond:QAGetAddress></soapenv:Body></soapenv:Envelope>';

        var headersTemplate = {
            "Content-Type": "text/xml"
        };

        var datasets = [];
        var currentLayouts = [];
        var defaultEngine;
        var defaultDataSet;
        var defaultLayout;
        var lastCanSearchResult = false;

        var preparedDoGetLayoutsPacket;
        var preparedDoCanSearchPacket;
        var preparedDoSearchPacket;
        var preparedDoGetAddressPacket;


        var prepareDoGetLayouts = function prepareDoGetLayouts() {
            preparedDoGetLayoutsPacket = doGetLayoutsPacket
                .replace('{{country}}', defaultDataSet !== undefined && defaultDataSet !== null ? defaultDataSet.getId() : '');
        };

        var prepareDoCanSearch = function prepareDoCanSearch() {
            preparedDoCanSearchPacket = doCanSearchPacket
                .replace('{{layout}}', defaultLayout ? defaultLayout.getName() : '')
                .replace('{{country}}', defaultDataSet !== undefined && defaultDataSet !== null ? defaultDataSet.getId() : '')
                .replace('{{engine}}', defaultEngine.getEngineType());
        };

        var prepareDoSearch = function prepareDoSearch() {
            preparedDoSearchPacket = doSearchPacket
                .replace("{{flatten}}", defaultEngine.isFlatten())
                .replace("{{country}}", defaultDataSet !== undefined && defaultDataSet !== null ? defaultDataSet.getId() : '')
                .replace("{{engine}}", defaultEngine.getEngineType())
                .replace("{{intensity}}", defaultEngine.getIntensity())
                .replace("{{promptSet}}", defaultEngine.getPromptSet())
                .replace("{{threshold}}", defaultEngine.getThreshold())
                .replace("{{timeout}}", defaultEngine.getTimeout())
                .replace('{{layout}}', defaultLayout !== undefined && defaultLayout !== null ? defaultLayout.getName() : '');
        };

        var prepareDoGetAddress = function prepareDoGetAddress() {
            preparedDoGetAddressPacket = doGetAddressPacket
                .replace("{{layout}}", defaultLayout !== undefined && defaultLayout !== null ? defaultLayout.getName() : '');
        };

        function logError(result, xml) {
            try {
                // Try parse XML.
                $.parseXML(xml);

                // No failure, log as generic error.
                dq.logger.error('Request error (usually caused by invalid token or license not supported): ' + xml);
            } catch (e) {
                // XML parse error, log xml error.
                dq.logger.error('XML error (' + e + '): ' + xml);
            }
        }

        this.setEngine = function (engine) {
            if (engine instanceof lib.Engine) {
                defaultEngine = engine;
            } else {
                throw 'Parameter is not an Engine object';
            }
        };

        this.setDataSet = function (dataSet) {
            if (typeof dataSet === 'string') {
                for (var dataSetIndex = 0; dataSetIndex < datasets.length; dataSetIndex++) {
                    if (datasets[dataSetIndex].id === dataSet) {
                        dataSet = datasets[dataSetIndex];
                        break;
                    }
                }
            }

            if (dataSet instanceof lib.DataSet) {
                defaultDataSet = dataSet;
            } else {
                throw 'Parameter is not a DataSet object';
            }
        };

        this.getDataSet = function () {
            return defaultDataSet;
        };

        this.setLayout = function (layout) {
            if (layout instanceof lib.Layout) {
                defaultLayout = layout;
            } else if (layout === null || layout === undefined) {
                defaultLayout = new lib.Layout('', '');
            } else {
                throw 'Parameter is not a Layout object';
            }
        };

        this.getLayout = function (layout) {
            var ret;
            currentLayouts.forEach(function (lo) {
                if (lo.getId() === layout) {
                    ret = lo;
                }
            });
            if (ret) {
                return ret;
            } else {
                throw "Layout not found for current dataset.";
            }
        };

        this.doGetData = function () {
            return lib.promise.call(this, function (dfd) {
                var headers = headersTemplate;
                headers['Auth-Token'] = dq.Tokens[dq.TokenEnum.ADDRESS];
                headers['SoapAction'] = "http://www.qas.com/OnDemand-2011-03/DoGetData";

                var content = doGetDataPacket;
                var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.ADDRESS, headers, doGetDataPacket, EXP.DataTypeEnum.XML);
                promise.done(function (evt, result, data) {
                    lib.rejectIfThrow.call(this, dfd, function (dfd) {
                        var response = JSON.parse(dq.convertXml2Json(data.responseXML));
                        var datasetArray = [];
                        var jDataSets = response['soap:Envelope']['soap:Body'].QAData;
                        if ($.type(jDataSets.DataSet) === "array") {
                            jDataSets.DataSet.forEach(function (entry) {
                                datasetArray.push(new lib.DataSet(entry.ID, entry.Name));
                            });
                        } else if ($.type(jDataSets.DataSet) === "object") {
                            datasetArray.push(new lib.DataSet(jDataSets.DataSet.ID, jDataSets.DataSet.Name));
                        }
                        var dataResult = new lib.QAData(datasetArray);
                        dfd.resolve(dataResult);
                    });
                }).fail(function (evt, result, data) {
                    logError(result, content);
                    dfd.reject(_self.options.errorText);
                });
            });
        };

        this.doGetLayouts = function () {
            prepareDoGetLayouts();
            var content = preparedDoGetLayoutsPacket;

            return lib.promise.call(this, function (dfd) {
                var headers = headersTemplate;
                headers['Auth-Token'] = dq.Tokens[EXP.DQ.TokenEnum.ADDRESS];
                headers['SoapAction'] = "http://www.qas.com/OnDemand-2011-03/DoGetLayouts";

                var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.ADDRESS, headers, content, EXP.DataTypeEnum.XML);
                promise.done(function (evt, result, data) {
                    lib.rejectIfThrow.call(this, dfd, function (dfd) {
                        var response = JSON.parse(dq.convertXml2Json(data.responseXML));
                        var layoutArray = [];
                        var jLayouts = response['soap:Envelope']['soap:Body'].QALayouts;
                        if ($.type(jLayouts.Layout) === "array") {
                            jLayouts.Layout.forEach(function (entry) {
                                layoutArray.push(new lib.Layout(entry.Name, entry.Comment));
                            });
                        } else if ($.type(jLayouts.Layout) === "object") {
                            layoutArray.push(new lib.Layout(jLayouts.Layout.Name, jLayouts.Layout.Comment));
                        }
                        var layoutResult = new lib.QALayouts(layoutArray);
                        currentLayouts = layoutResult.getLayouts();
                        dfd.resolve(layoutResult.getLayouts());
                    });
                }).fail(function (evt, result, data) {
                    logError(result, content);
                    dfd.reject(_self.options.errorText);
                });
            });
        };

        this.doCanSearch = function () {
            prepareDoCanSearch();

            return lib.promise.call(this, function (dfd) {
                var headers = headersTemplate;
                headers['Auth-Token'] = dq.Tokens[EXP.DQ.TokenEnum.ADDRESS];
                headers['SoapAction'] = "http://www.qas.com/OnDemand-2011-03/DoCanSearch";

                var content = preparedDoCanSearchPacket;
                var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.ADDRESS, headers, content, EXP.DataTypeEnum.XML);
                promise.done(function (evt, result, data) {
                    lib.rejectIfThrow.call(this, dfd, function (dfd) {
                        var response = JSON.parse(dq.convertXml2Json(data.responseXML));
                        var layoutArray = [];
                        var jOk = response['soap:Envelope']['soap:Body'].QASearchOk;
                        var isOk = jOk.IsOk;
                        if (isOk === "true") {
                            lastCanSearchResult = new lib.QASearchOk(isOk);
                        } else {
                            lastCanSearchResult = new lib.QASearchOk(isOk, jOk.ErrorCode, jOk.ErrorMessage);
                        }
                        dfd.resolve(lastCanSearchResult);
                    });
                }).fail(function (evt, result, data) {
                    logError(result, content);
                    dfd.reject(_self.options.errorText);
                });
            });
        };

        this.doSearch = function (search) {
            prepareDoSearch();

            return lib.promise.call(this, function (dfd) {
                if (search.length > 200) {
                    dfd.reject("Search term is too long");
                }

                var content = preparedDoSearchPacket.replace("{{searchTerm}}", search);
                var headers = headersTemplate;
                headers['Auth-Token'] = dq.Tokens[EXP.DQ.TokenEnum.ADDRESS];
                headers['SoapAction'] = "http://www.qas.com/OnDemand-2011-03/DoSearch";

                var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.ADDRESS, headers, content, EXP.DataTypeEnum.XML);
                promise.done(function (evt, result, data) {

                    lib.rejectIfThrow.call(this, dfd, function (dfd) {
                        var response = JSON.parse(dq.convertXml2Json(data.responseXML));
                        var jSearchResult = response['soap:Envelope']['soap:Body'].QASearchResult;
                        var jPicklist = jSearchResult.QAPicklist;

                        var picklistArray = [];

                        // Add "Override" or "Use entered" picklist item as the first item
                        var override = null;
                        if (jPicklist.FullPicklistMoniker !== undefined && jPicklist.FullPicklistMoniker !== null) {
                            var overridePicklistItem = _self.options.overridePrefix + search + _self.options.overrideSuffix;
                            override = new lib.QAPicklistEntry(overridePicklistItem, jPicklist.FullPicklistMoniker, overridePicklistItem, overridePicklistItem, '', '0');
                        }

                        // Parse SOAP response for picklist items
                        if ($.type(jPicklist.PicklistEntry) === "array") {
                            jPicklist.PicklistEntry.forEach(function (entry) {
                                picklistArray.push(new lib.QAPicklistEntry(entry['@fullAddress'], entry.Moniker, entry.PartialAddress, entry.Picklist, entry.Postcode, entry.Score));
                            });
                        } else if ($.type(jPicklist.PicklistEntry) === "object") {
                            var entry = jPicklist.PicklistEntry;
                            picklistArray.push(new lib.QAPicklistEntry(entry['@fullAddress'], entry.Moniker, entry.PartialAddress, entry.Picklist, entry.Postcode, entry.Score));
                        }

                        // Remove "No matches" picklist item
                        for (var picklistIndex in picklistArray) {
                            if(typeof picklistArray[picklistIndex].picklist !== 'string') {
                                continue;
                            }

                            if (picklistArray[picklistIndex].picklist.toUpperCase() === "No matches".toUpperCase()) {
                                picklistArray.splice(picklistIndex, 1);
                                break;
                            }
                        }

                        // Prepare the final picklist container to be returned.
                        var picklist = new lib.QAPicklist(jPicklist.FullPicklistMoniker, picklistArray, jPicklist.Prompt, jPicklist.Total, jPicklist['@OverThreshold'], jPicklist['@MoreOtherMatches']);

                        var searchResult = new lib.QASearchResult(picklist, override);

                        dfd.resolve(searchResult);
                    });

                }).fail(function (evt, result, data) {
                    logError(result, content);
                    dfd.reject(_self.options.errorText);
                });
            });
        };

        this.doGetAddress = function (moniker) {
            prepareDoGetAddress();
            var content = preparedDoGetAddressPacket.replace("{{moniker}}", moniker);

            return lib.promise.call(this, function (dfd) {

                var headers = headersTemplate;
                headers['Auth-Token'] = dq.Tokens[EXP.DQ.TokenEnum.ADDRESS];
                headers['SoapAction'] = "http://www.qas.com/OnDemand-2011-03/DoGetAddress";

                var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.ADDRESS, headers, content, EXP.DataTypeEnum.XML);
                promise.done(function (evt, result, data) {
                    lib.rejectIfThrow.call(this, dfd, function (dfd) {
                        var response = JSON.parse(dq.convertXml2Json(data.responseXML));
                        var addressLineArray = [];
                        var jAddress = response['soap:Envelope']['soap:Body'].Address.QAAddress;
                        if ($.type(jAddress.AddressLine) === 'object') {
                            var newArr = [];
                            newArr.push(jAddress.AddressLine);
                            jAddress.AddressLine = newArr;
                        }

                        var formattedAddressMap = {};

                        if ($.type(jAddress.AddressLine) === "array") {
                            jAddress.AddressLine.forEach(function (entry, entryIndex) {
                                var dpGroup = [];
                                if (entry.LineContent === 'DataPlus') {
                                    if ($.type(entry.DataPlusGroup) === 'object') {
                                        var newArr = [];
                                        newArr.push(entry.DataPlusGroup);
                                        entry.DataPlusGroup = newArr;
                                    }
                                    if ($.type(entry.DataPlusGroup) === 'array') {
                                        entry.DataPlusGroup.forEach(function (dpgEntry) {
                                            var dpItemsArray = [];
                                            if ($.type(dpgEntry.DataPlusGroupItem) === 'array') {
                                                dpgEntry.DataPlusGroupItem.forEach(function (dpEntry) {
                                                    dpItemsArray.push(dpEntry);
                                                });
                                            } else if ($.type(dpgEntry.DataPlusGroupItem) === 'object') {
                                                dpItemsArray.push(dpgEntry.DataPlusGroupItem);
                                            }
                                            var dGroup = new lib.DataPlusGroup(dpgEntry.GroupName, dpItemsArray);
                                            dpGroup.push(dGroup);
                                        });
                                    }
                                }

                                var label = typeof entry.Label === 'string' ? entry.Label : 'addressLine' + (entryIndex + 1);
                                var line = typeof entry.Line === 'string' ? entry.Line : '';

                                formattedAddressMap[label] = line;

                                addressLineArray.push(new lib.AddressLine(label, line, entry.LineContent, dpGroup, entry.Overflow, entry.Truncated));
                            });
                        }

                        // Add shorthand to access layout line value
                        // e.g. result.get('addressLine1')
                        // e.g. result.get(0)
                        addressLineArray.get = function (i) {
                            if (typeof i === 'number') {
                                return this.address[i].line || '';
                            } else if (typeof i === 'string') {
                                return this.address[i] || '';
                            } else {
                                return '';
                            }
                        };

                        var addressResult = new lib.QAAddress(addressLineArray, jAddress.Overflow, jAddress.Truncated, jAddress.DPVStatus);

                        addressResult.address = formattedAddressMap;

                        dfd.resolve(addressResult);
                    });
                }).fail(function (evt, result, data) {
                    logError(result, content);
                    dfd.reject(_self.options.errorText);
                });
            });
        };
    };

    // Extend this object with a new instance of soap client.
    // This allows usage of soap api using the namespace.
    $.extend(lib, new lib.SoapClient());

    /**
     * A class to determine whether to use SOAP or REST API based on the given options and parameters.
     */
    lib.ClientBuilder = function () {
        var _self = this;
        _self.options = {};

        _self.withOptions = function (newOptions) {
            $.extend(_self.options, newOptions);

            return this;
        };
        _self.withDataset = function (dataset) {
            _self.options.dataset = dataset;
            return this;
        };
        _self.withEngine = function (engine) {
            _self.options.engine = engine;
            return this;
        };
        _self.build = function () {
            // Proxy only supports REST
            if (_self.options.isProxy !== undefined &&
                _self.options.isProxy !== null &&
                _self.options.isProxy) {
                return new lib.RestClient(_self.options);
            }

            // Specific intuitive engine can only be supported via REST
            if (_self.options.engine.toUpperCase() === 'INTUITIVE' && _self.options.dataset.toUpperCase() === 'GBR') {
                return new lib.RestClient(_self.options);
            }

            return new lib.SoapClient(_self.options);
        };
    };

    /**
     * A generic address search client.
     * Token, REST Proxy URL is single setting for all search instances, thus defined in constructor as options.
     */
    lib.Client = function (options) {
        var _self = this;

        // Options, built manually.
        _self.options = {
            timeout: 10 * 1000,
            token: '',
            soapUrl: 'https://ws3.ondemand.qas.com/ProOnDemand/V3/ProOnDemandService.asmx',
            restUrl: 'https://api.edq.com/capture/address/v2/',
            isProxy: false,
            engine: 'INTUITIVE',
            errorText: 'Failed to communicate with address validation service'
        };

        $.extend(_self.options, options);

        /**
         * Search for address.
         * @param {string} query Text containing address to be searched for.
         * @param {string} dataset Dataset or country to search for.
         * @param {string} layout Layout name to be used. Defaults to empty string.
         * @param {string} engine Engine name to be used. Defaults to Intuitive engine.
         */
        this.search = function (query, dataset, layout, engine) {
            if (engine === undefined || engine === null) {
                engine = 'Intuitive';
            }
            if (layout === undefined || layout === null) {
                layout = '';
            }

            var client = new lib.ClientBuilder()
            .withOptions(_self.options)
            .withDataset(dataset)
            .withEngine(engine)
            .build();

            return client.search(query, dataset, layout, engine);
        };

        /**
         * Get final address using a moniker a.k.a format address.
         * @param {string} moniker Moniker returned in the Search step, which is used to identify address to be formatted.
         * @param {string} dataset Dataset or country to search for.
         * @param {string} layout Layout used to format the address. Address components are defined in layout.
         * @param {string} engine Engine name to be used. Defaults to Intuitive engine.
         */
        this.format = function (moniker, dataset, layout, engine) {
            if (engine === undefined || engine === null) {
                engine = 'Intuitive';
            }

            var client = new lib.ClientBuilder()
            .withOptions(_self.options)
            .withDataset(dataset)
            .withEngine(engine)
            .build();

            return client.format(moniker, dataset, layout, engine);
        };
    };

    lib.promise = function (fn) {
        var dfd = $.Deferred();
        try { fn.call(this, dfd); } catch (e) {
            dfd.reject(e);
        }
        return dfd.promise();
    };

    lib.rejectIfThrow = function (promise, fn) {
        try { fn.call(this, promise); } catch (e) { promise.reject(e); }
        return promise;
    };

    /**
     * A REST address search client.
     */
    lib.RestClient = function (options) {
        var _self = this;

        _self.options = {};
        $.extend(_self.options, options);

        /**
         * Search for address.
         * @param {string} query Text containing address to be searched for.
         * @param {string} dataset Dataset or country to search for.
         * @param {string} layout Optional (no default) Layout to be used.
         * @param {string} engine Optional (default Intuitive) Engine name to be used.
         */
        this.search = function (query, dataset, layout, engine) {
            return lib.promise.call(this, function (promise) {
                var queryString = "query=" + query + "&country=" + dataset;

                if (layout !== undefined && layout !== null) {
                    queryString += "&layout=" + layout;
                }

                if (engine !== undefined && engine !== null) {
                    queryString += "&engine=" + engine;
                }

                if (_self.options.token !== undefined && _self.options.token !== null) {
                    queryString += "&auth-token=" + _self.options.token;
                }

                var ajaxOptions = {
                    url: _self.options.restUrl + '/search',
                    data: queryString,
                    headers: { 'Auth-Token': _self.options.token },
                    dataType: "json",
                    timeout: _self.options.timeout
                };

                $.ajax(ajaxOptions)
                    .done(function (data) {
                    lib.rejectIfThrow.call(this, promise, function (promise) {
                        var picklistArray = [];
                        for (var picklistIndex in data.results) {
                            var item = data.results[picklistIndex];

                            // The API's response for moniker is a URL
                            // Extract the moniker here
                            var formatUrl = item.format;
                            if (item.format === null) {
                                // "No match" item in the picklist item found. Try do nothing...
                                continue;
                            } else {
                                var moniker = /.*id=([^&]*)/g.exec(formatUrl)[1];
                                picklistArray.push(new lib.QAPicklistEntry(item.suggestion, moniker, item.suggestion, item.suggestion, '', ''));
                            }
                        }

                        var override = null;
                        if (picklistArray.length > 0 && picklistArray[0].fullAddress.indexOf('Use entered') === 0) {
                            override = picklistArray[0];
                            picklistArray.splice(0, 1);
                        }

                        var picklist = new lib.QAPicklist('', picklistArray, '', data.count, '', '');
                        var searchResult = new lib.QASearchResult(picklist, override);

                        promise.resolve(searchResult);
                    });
                })
                    .fail(promise.reject);
            });
        };

        /**
         * Get final address using a moniker a.k.a format address.
         * @param {string} moniker Moniker returned in the Search step, which is used to identify address to be formatted.
         * @param {string} dataset Dataset or country to search for.
         * @param {string} layout Layout used to format the address. Address components are defined in layout.
         * @param {string} engine Optional (default Intuitive). Engine name to be used.
         */
        this.format = function (moniker, dataset, layout, engine) {
            return lib.promise.call(this, function (promise) {
                var queryString = "id=" + moniker + "&country=" + dataset + "&layout=" + layout;

                if (layout !== undefined && layout !== null) {
                    queryString += "&layout=" + layout;
                }

                if (_self.options.token !== undefined && _self.options.token !== null) {
                    queryString += "&auth-token=" + _self.options.token;
                }

                $.ajax({
                    url: _self.options.restUrl + '/format',
                    data: queryString,
                    headers: { 'Auth-Token': _self.options.token },
                    dataType: "json",
                    timeout: _self.options.timeout
                })
                    .done(function (data) {
                    lib.rejectIfThrow.call(this, promise, function (promise) {
                        var formattedAddress = {};
                        $.map(data.address, function (addressObj) {
                            var layoutLineName = Object.getOwnPropertyNames(addressObj)[0];
                            formattedAddress[layoutLineName] = addressObj[layoutLineName];
                        });

                        var addressLineArray = [];
                        for (var dataIndex in data.address) {
                            var item = data.address[dataIndex];
                            var layoutLineName = Object.getOwnPropertyNames(item)[0];

                            var label = typeof layoutLineName === 'string' ? layoutLineName : 'addressLine' + (dataIndex + 1);

                            addressLineArray.push(new lib.AddressLine(label, item[layoutLineName], '', '', false, false));
                        }

                        // Add shorthand to access layout line value
                        // e.g. result.get('addressLine1')
                        // e.g. result.get(0)
                        addressLineArray.get = function (i) {
                            if (typeof i === 'number') {
                                return this[i].line || '';
                            } else if (typeof i === 'string') {
                                return this[i] || '';
                            } else {
                                return '';
                            }
                        };

                        var addressResult = new lib.QAAddress(addressLineArray, '', '', '');
                        addressResult.address = formattedAddress;

                        promise.resolve(addressResult, dataset);
                    });
                })
                    .fail(function (e) { promise.reject(e); });
            });
        };
    };

    // Classes DoGetData
    lib.QAData = function QAData(dataSets) {
        this.dataSets = dataSets;
    };
    lib.DataSet = function DataSet(id, name) {
        if (id === undefined || id === null || id.length !== 3) {
            throw 'Dataset ID must be a string with length 3.';
        }

        this.id = id;
        this.name = name;
    };
    lib.DataSet.prototype.getId = function () {
        return this.id;
    };
    lib.DataSet.prototype.getName = function () {
        return this.name;
    };

    //Classes DoGetLayouts
    lib.QALayouts = function QALayouts(layouts) {
        this.layouts = layouts;
    };
    lib.QALayouts.prototype.getLayouts = function () {
        return this.layouts;
    };

    lib.Layout = function Layout(name, comment) {
        this.name = name;
        this.comment = comment;
    };
    lib.Layout.prototype.getName = function () {
        return this.name;
    };
    lib.Layout.prototype.getComment = function () {
        return this.comment;
    };

    // Classes DoSearch  
    lib.QASearchResult = function QASearchResult(qaPicklist, overridePicklistItem) {
        this.qaPicklist = qaPicklist;

        // Easy-access property to search results.
        this.picklist = [];
        for (var itemIndex in this.qaPicklist.picklistArray) {
            this.picklist.push(this.qaPicklist.picklistArray[itemIndex]);
        }

        this.override = overridePicklistItem;
        this.hasOverride = typeof overridePicklistItem !== 'undefined' && overridePicklistItem !== null;
    };

    lib.QASearchResult.prototype.getPicklist = function () {
        return this.qaPicklist;
    };

    lib.QASearchResult.prototype.getOverride = function () {
        return this.override;
    };

    lib.QAPicklist = function QAPicklist(fullPicklistMoniker, picklistArray, prompt, total, overThreshold, moreMatches) {
        this.fullPicklistMoniker = fullPicklistMoniker;
        this.picklistArray = picklistArray;
        this.prompt = prompt;
        this.total = parseInt(total);
        this.overThreshold = overThreshold;
        this.moreMatches = moreMatches;
    };
    lib.QAPicklist.prototype.getMoniker = function () {
        return this.fullPicklistMoniker;
    };
    lib.QAPicklist.prototype.getPicklistItems = function () {
        return this.picklistArray;
    };
    lib.QAPicklist.prototype.getPrompt = function () {
        return this.prompt;
    };
    lib.QAPicklist.prototype.getTotal = function () {
        return this.total;
    };
    lib.QAPicklist.prototype.isOverThreshold = function () {
        return this.overThreshold;
    };
    lib.QAPicklist.prototype.isMoreMatches = function () {
        return this.moreMatches;
    };
    lib.QAPicklist.prototype.getSimplifiedPicklistArray = function () {
        var array = [];
        this.picklistArray.forEach(function (picklistEntry) {
            array.push({
                label: picklistEntry.getPartialAddress(),
                value: picklistEntry.getMoniker()
            });
        });
        return array;
    };

    lib.QAPicklistEntry = function QAPicklistEntry(fullAddress, moniker, partialAddress, picklist, postcode, score) {
        this.fullAddress = fullAddress || partialAddress;
        this.moniker = moniker;
        this.partialAddress = partialAddress;
        this.picklist = picklist;
        this.postcode = postcode;
        this.score = score;
        this.isOverride = typeof this.fullAddress === 'string' && this.fullAddress.indexOf('Use entered') === 0;
    };

    lib.QAPicklistEntry.prototype.getFullAddress = function () {
        return this.fullAddress;
    };
    lib.QAPicklistEntry.prototype.getMoniker = function () {
        return this.moniker;
    };
    lib.QAPicklistEntry.prototype.getPartialAddress = function () {
        return this.partialAddress;
    };
    lib.QAPicklistEntry.prototype.getPicklistText = function () {
        return this.picklist;
    };
    lib.QAPicklistEntry.prototype.getPostcode = function () {
        return this.postcode;
    };
    lib.QAPicklistEntry.prototype.getScore = function () {
        return this.score;
    };

    // Classes DoCanSearch
    lib.QASearchOk = function QASearchOk(isOk, errorCode, errorMessage) {
        this.isOk = isOk === 'true' ? true : false;
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    };
    lib.QASearchOk.prototype.isOk = function () {
        return this.isOk;
    };
    lib.QASearchOk.prototype.getErrorCode = function () {
        return this.errorCode;
    };
    lib.QASearchOk.prototype.getErrorMessage = function () {
        return this.errorMessage;
    };

    // Classes DoGetAddress
    lib.QAAddress = function QAAddress(addressLines, overflow, truncated, dpvStatus) {
        this.addressLines = addressLines;
        this.overflow = overflow;
        this.truncated = truncated;
        this.dpvStatus = dpvStatus;
        this.addressLinesMap = {};
    };
    lib.QAAddress.prototype.getAddressLines = function () {
        return this.addressLines;
    };
    lib.QAAddress.prototype.getAddressLineByLabel = function (label) {
        if (!this.addressLinesMap.hasOwnProperty(label)) {
            throw "No address line with this label";
        } else {
            return this.addressLinesMap[label];
        }
    };
    lib.QAAddress.prototype.isOverflow = function () {
        return this.overflow;
    };
    lib.QAAddress.prototype.isTruncated = function () {
        return this.truncated;
    };
    lib.QAAddress.prototype.getDpvStatus = function () {
        return this.dpvStatus;
    };

    lib.AddressLine = function AddressLine(label, line, lineContent, dataplusGroup, overflow, truncated) {
        this.label = label;
        this.line = line;
        this.lineContent = lineContent;
        this.dataplusGroup = dataplusGroup;
        this.overflow = overflow;
        this.truncated = truncated;
    };
    lib.AddressLine.prototype.getLabel = function () {
        return this.label;
    };
    lib.AddressLine.prototype.getLine = function () {
        return this.line;
    };
    lib.AddressLine.prototype.getLineContent = function () {
        return this.lineContent;
    };
    lib.AddressLine.prototype.getDataplusGroup = function () {
        return this.dataplusGroup;
    };
    lib.AddressLine.prototype.isOverflow = function () {
        return this.overflow;
    };
    lib.AddressLine.prototype.isTruncated = function () {
        return this.truncated;
    };

    lib.DataPlusGroup = function DataPlusGroup(groupName, items) {
        this.groupName = groupName;
        this.items = items;
    };
    lib.DataPlusGroup.prototype.getGroupName = function () {
        return this.groupName;
    };
    lib.DataPlusGroup.prototype.getItems = function () {
        return this.items;
    };

    // Classes Engine
    lib.EngineEnum = {
        INTUITIVE: "Intuitive"//,
        //SINGLELINE: "Singleline",
        //TYPEDOWN: "Typedown",
        //VERIFICATION: "Verification",
        //KEYFINDER: "Keyfinder"
    };
    lib.IntensityEnum = {
        EXACT: "Exact",
        CLOSE: "Close",
        EXTENSIVE: "Extensive"
    };
    lib.PromptSetEnum = {
        DEFAULT: "Default",
        OPTIMAL: "Optimal",
        ALTERNATIVE: "Alternative",
        GENERIC: "Generic",
        ONELINE: "OneLine"
    };
    lib.Engine = function Engine(options) {
        var oengineType = options.engineType;
        var oflatten = options.flatten === undefined ? true : options.flatten;
        var ointensity = options.intensity === undefined ? lib.IntensityEnum.CLOSE : options.intensity;
        var opromptSet = options.promptSet === undefined ? lib.PromptSetEnum.DEFAULT : options.promptSet;
        var othreshold = options.threshold === undefined ? 25 : options.threshold;
        var otimeout = options.timeout === undefined ? 10000 : options.timeout;


        for (var engineNum in lib.EngineEnum) {
            if (lib.EngineEnum.hasOwnProperty(engineNum)) {
                if (oengineType === lib.EngineEnum[engineNum]) {
                    this.engineType = lib.EngineEnum[engineNum];
                    break;
                }
            }
        }
        if (!this.engineType) {
            throw 'Engine type not recognised';
        }
        if ($.type(oflatten) === "boolean") {
            this.flatten = oflatten;
        } else {
            throw "Flatten must be set to true or false";
        }
        if ($.type(othreshold) === "number") {
            this.threshold = othreshold;
        } else {
            throw "Threshold must be a number";
        }
        if ($.type(otimeout) === "number") {
            this.timeout = otimeout;
        } else {
            throw "Timeout must be a number";
        }
        for (var intensityVal in lib.IntensityEnum) {
            if (lib.IntensityEnum.hasOwnProperty(intensityVal)) {
                if (ointensity === lib.IntensityEnum[intensityVal]) {
                    this.intensity = lib.IntensityEnum[intensityVal];
                    break;
                }
            }
        }
        for (var promptSetVal in lib.PromptSetEnum) {
            if (lib.PromptSetEnum.hasOwnProperty(promptSetVal)) {
                if (opromptSet === lib.PromptSetEnum[promptSetVal]) {
                    this.promptSet = lib.PromptSetEnum[promptSetVal];
                    break;
                }
            }
        }

        if (!this.intensity) {
            throw "Engine intensity value not recognised.";
        }

        if (!this.promptSet) {
            throw "Promptset value not recognised.";
        }
    };
    lib.Engine.prototype.getEngineType = function () {
        return this.engineType;
    };
    lib.Engine.prototype.isFlatten = function () {
        return this.flatten;
    };
    lib.Engine.prototype.getIntensity = function () {
        return this.intensity;
    };
    lib.Engine.prototype.getPromptSet = function () {
        return this.promptSet;
    };
    lib.Engine.prototype.getThreshold = function () {
        return this.threshold;
    };
    lib.Engine.prototype.getTimeout = function () {
        return this.timeout;
    };

    return lib;
} (EXP.DQ.Address || {}, EXP.DQ, jQuery));
/*! contact-data-services.js | https://github.com/experiandataquality/RealTimeAddress | Apache-2.0
*   Experian Data Quality | https://github.com/experiandataquality */

; (function (window, document, undefined) {

    function isElement(obj) {
        try {
            //Using W3 DOM2 (works for FF, Opera and Chrom)
            return obj instanceof HTMLElement;
        }
        catch (e) {
            //Browsers not supporting W3 DOM2 don't have HTMLElement and
            //an exception is thrown and we end up here. Testing some
            //properties that all elements have. (works on IE7)
            return (typeof obj === "object") &&
                (obj.nodeType === 1) && (typeof obj.style === "object") &&
                (typeof obj.ownerDocument === "object");
        }
    }

    // Create ContactDataServices constructor and namespace on the window object (if not already present)
    var ContactDataServices = window.ContactDataServices = window.ContactDataServices || {};

    // DEFAULT SETTINGS - CHANGE ME!
    ContactDataServices.defaults = {
        input: { placeholderText: "Start typing an address...", applyFocus: false },
        resultMapping: [],
        formattedAddressContainer: null,
        //formattedAddressContainer: { showHeading: false, headingType: "h3", validatedHeadingText: "Validated address", manualHeadingText: "Manual address entered" },
        continueTypingText: "Enter more information to narrow down your search. E.g. 60 Lett",
        notFoundText: "Enter more information to narrow down your search. E.g. 60 Lett",
        searchAgain: { visible: true, text: "Search again" },
        useAddressEnteredText: "Click here to enter address manually.",
        useSpinner: false,
        language: "en",
        layout: 'IntuitiveDefault',
        searchDelay: 500,
        hideDelay: 3000,
        searchTermMinLength: 6,
        addressLineLabels: [
            "addressLine1",
            "addressLine2",
            "addressLine3",
            "locality",
            "province",
            "postalCode",
            "country"
        ]
    };

    // Create configuration by merging custom and default options
    ContactDataServices.mergeDefaultOptions = function (customOptions) {
        var instance = customOptions || {};

        instance.enabled = true;
        instance.searchDelay = instance.searchDelay || ContactDataServices.defaults.searchDelay;
        instance.hideDelay = instance.hideDelay || ContactDataServices.defaults.hideDelay;
        instance.searchTermMinLength = instance.searchTermMinLength || ContactDataServices.defaults.searchTermMinLength;
        instance.layout = instance.layout || ContactDataServices.defaults.layout;
        instance.language = instance.language || ContactDataServices.defaults.language;
        instance.useSpinner = instance.useSpinner || ContactDataServices.defaults.useSpinner;
        instance.lastSearchTerm = "";
        instance.currentSearchTerm = "";
        instance.lastCountryCode = "";
        instance.currentCountryCode = "";
        instance.currentSearchUrl = "";
        instance.currentFormatUrl = "";
        instance.applyFocus = (typeof instance.applyFocus !== "undefined") ? instance.applyFocus : ContactDataServices.defaults.input.applyFocus;
        instance.placeholderText = instance.placeholderText || ContactDataServices.defaults.input.placeholderText;
        instance.continueTypingText = instance.continueTypingText || ContactDataServices.defaults.continueTypingText;
        instance.notFoundText = instance.notFoundText || ContactDataServices.defaults.notFoundText;
        instance.errorText = instance.errorText || ContactDataServices.defaults.errorText;
        instance.searchAgain = instance.searchAgain || {};
        instance.searchAgain.visible = (typeof instance.searchAgain.visible !== "undefined") ? instance.searchAgain.visible : ContactDataServices.defaults.searchAgain.visible;
        instance.searchAgain.text = instance.searchAgain.text || ContactDataServices.defaults.searchAgain.text;
        instance.formattedAddressContainer = instance.formattedAddressContainer || ContactDataServices.defaults.formattedAddressContainer;
        if (typeof instance.formattedAddressContainer !== 'undefined' && instance.formattedAddressContainer !== null) {
            instance.formattedAddressContainer.showHeading = (typeof instance.formattedAddressContainer.showHeading !== "undefined") ? instance.formattedAddressContainer.showHeading : ContactDataServices.defaults.formattedAddressContainer.showHeading;
            instance.formattedAddressContainer.headingType = instance.formattedAddressContainer.headingType || ContactDataServices.defaults.formattedAddressContainer.headingType;
            instance.formattedAddressContainer.validatedHeadingText = instance.formattedAddressContainer.validatedHeadingText || ContactDataServices.defaults.formattedAddressContainer.validatedHeadingText;
            instance.formattedAddressContainer.manualHeadingText = instance.formattedAddressContainer.manualHeadingText || ContactDataServices.defaults.formattedAddressContainer.manualHeadingText;
        }
        instance.elements = instance.elements || {};
        instance.resultMapping = instance.resultMapping || ContactDataServices.defaults.resultMapping;

        return instance;
    };

    // Constructor method event listener (pub/sub type thing)
    ContactDataServices.eventFactory = function () {
        // Create the events object
        var events = {};

        // Create an object to hold the collection of events
        events.collection = {};

        // Subscribe a new event
        events.on = function (event, action) {
            // Create the property array on the collection object
            events.collection[event] = events.collection[event] || [];
            // Push a new action for this event onto the array
            events.collection[event].push(action);
        };

        // Publish (trigger) an event
        events.trigger = function (event, data) {
            // If this event is in our collection (i.e. anyone's subscribed)
            if (events.collection[event]) {
                // Loop over all the actions for this event
                for (var i = 0; i < events.collection[event].length; i++) {
                    // Create array with default data as 1st item
                    var args = [data];

                    // Loop over additional args and add to array
                    for (var a = 2; a < arguments.length; a++) {
                        args.push(arguments[a]);
                    }

                    // Call each action for this event type, passing the args
                    events.collection[event][i].apply(events.collection, args);
                }
            }
        };

        // Return the new events object to be used by whoever invokes this factory
        return events;
    };

    // Translations
    ContactDataServices.translations = {
        // language / country / property
        en: {
            gbr: {
                locality: "Town/City",
                province: "County",
                postalCode: "Post code"
            },
            usa: {
                locality: "City",
                province: "State",
                postalCode: "Zip code"
            }
        }
        // Add other languages below
    };

    // Method to handle showing of UA (User Assistance) content
    ContactDataServices.ua = {
        banner: {
            show: function (html) {
                // Retrieve the existing banner
                var banner = document.querySelector(".ua-banner");

                // Create a new banner if necessary
                if (!banner) {
                    var firstChildElement = document.querySelector("body").firstChild;
                    banner = document.createElement("div");
                    $(banner).addClass("ua-banner");
                    firstChildElement.parentNode.insertBefore(banner, firstChildElement.nextSibling);
                }

                // Apply the HTML content
                banner.innerHTML = html;
            },
            hide: function () {
                var banner = document.querySelector(".ua-banner");
                if (banner) {
                    banner.parentNode.removeChild(banner);
                }
            }
        }
    };

    // Generate the URLs for the various requests
    ContactDataServices.urls = {
        endpoint: "https://api.edq.com/capture/address/v2/search",
        construct: {
            address: {
                // Construct the Search URL by appending query, country & take
                search: function (instance) {
                    var url = ContactDataServices.urls.endpoint;
                    url += "?query=" + encodeURIComponent(instance.currentSearchTerm);
                    url += "&country=" + instance.currentCountryCode;
                    url += "&take=" + (instance.maxSize || instance.picklist.maxSize);
                    url += "&auth-token=" + instance.token;
                    return url;
                },
                // Append the token to the Format URL
                format: function (url, instance) {
                    return url + "&auth-token=" + instance.token;
                }
            }
        },
        // Get token from query string and set on instance
        getToken: function (instance) {
            if (!instance.token) {
                instance.token = ContactDataServices.urls.getParameter("token");
            }
        },
        getParameter: function (name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        }
    };

    // Integrate with address searching
    ContactDataServices.address = function (customOptions) {
        // Build our new instance by merging custom and default options
        var instance = ContactDataServices.mergeDefaultOptions(customOptions);

        // Resize picklist according to target search field.
        function resizePicklist() {
            var input = $(instance.elements.input);
            $('.address-picklist-container').width(input.outerWidth());
            var inputPosition = input.offset();
            inputPosition.top += input.outerHeight();
            $('.address-picklist-container').offset(inputPosition);
        }

        // Create a new object to hold the events from the event factory
        instance.events = new ContactDataServices.eventFactory();

        // Initialise this instance
        instance.init = function () {
            // Get token from the query string
            ContactDataServices.urls.getToken(instance);
            if (!instance.token) {
                // Disable searching on this instance
                instance.enabled = false;
                // Display a banner informing the user that they need a token
                window.alert('No tokens configured for address search.');
                return;
            }

            instance.setCountryList();

            if (instance.elements.input) {
                instance.input = instance.elements.input;

                // Prevent form submit if focus is on search text. Essentially disable ENTER for search text field.
                $(instance.input).parents('form')
                    .keydown(function (e) {
                    if (e.keyCode === 13 && $(instance.input).is(':focus')) {
                        return false;
                    }
                });

                // Bind an event listener on the input
                $(instance.input).on("keyup", instance.search);
                $(instance.input).on("keydown", instance.checkTab);
                // Set a placeholder for the input
                instance.input.setAttribute("placeholder", instance.placeholderText);
                // Disable autocomplete on the form
                instance.input.parentNode.setAttribute("autocomplete", "off");
                instance.input.setAttribute("autocomplete", "off");

                // Apply focus to input
                if (instance.applyFocus) {
                    instance.input.focus();
                }

                // Attach event to resize picklist when created, and also resize when first loads.
                instance.events.on("pre-picklist-create", resizePicklist);
                $(instance.elements.input).resize(resizePicklist);
            }

            // Disable all inputs not used for search.
            var mapFn = function (e) {


                // Do nothing for input used for search.
                if (this === instance.elements.input) {
                    return;
                }

                if ($(this).is('input') || $(this).is('text') || $(this).is('select')) {
                    $(this).attr('disabled', true);
                    $(this).parents('.mktoFormRow').attr('style','visibility: hidden; position: absolute;');
                }

            };

            for (var mappingIndex = 0; mappingIndex < instance.resultMapping.length; mappingIndex++) {
                var mappingObj = instance.resultMapping[mappingIndex];
                $(mappingObj.selector).each(mapFn);
            }
        };

        // Main function to search for an address from an input string
        instance.search = function (event) {

            // Clear error.
            instance.error = null;

            // Handle keyboard navigation
            var e = event || window.event;
            e = e.which || e.keyCode;
            if (e === 38/*Up*/ || e === 40/*Down*/ || e === 13/*Enter*/) {
                var bubbleEvent = instance.picklist.keyup(e);
                if (typeof bubbleEvent === 'boolean' && !bubbleEvent) {
                    return false;
                }

                return true;
            }

            if (instance.searchDelayTimer) {
                clearTimeout(instance.searchDelayTimer);
            }

            instance.searchDelayTimer = setTimeout(function () {
                instance.searchDelayTimer = null;
                instance.currentSearchTerm = instance.input.value;
                instance.currentCountryCode = instance.countryList.value;

                // Check is searching is permitted
                if (instance.canSearch()) {
                    // Abort any outstanding requests
                    if (instance.request.currentRequest) {
                        instance.request.currentRequest.abort();
                    }

                    // Fire an event before a search takes place
                    instance.events.trigger("pre-search", instance.currentSearchTerm);

                    // Construct the new Search URL
                    var url = ContactDataServices.urls.construct.address.search(instance);

                    // Store the last search term
                    instance.lastSearchTerm = instance.currentSearchTerm;

                    // Hide any previous results
                    instance.result.hide();

                    // Hide the inline search spinner
                    instance.searchSpinner.hide();

                    // Show an inline spinner whilst searching
                    instance.searchSpinner.show();

                    // Initiate new Search request
                    var _callBack = instance.picklist.show;

                    if (instance.currentSearchTerm.length < instance.searchTermMinLength) {
                        _callBack({ results: [] });
                    } else {

                        var client = new EXP.DQ.Address.Client({ token: instance.token, errorText: instance.errorText });
                        client.search(instance.currentSearchTerm, instance.currentCountryCode)
                            .done(function (result) {
                            var r = {};
                            r.hasOverride = result.hasOverride;
                            r.override = {
                                suggestion: ContactDataServices.defaults.useAddressEnteredText,
                                format: result.override.moniker
                            };
                            r.count = result.picklist.length;
                            r.results = [];
                            for (var picklistIndex = 0; picklistIndex < result.picklist.length; picklistIndex++) {
                                r.results.push({
                                    suggestion: result.picklist[picklistIndex].partialAddress,
                                    format: result.picklist[picklistIndex].moniker,
                                    matched: []
                                });
                            }

                            _callBack(r);
                        })
                            .fail(function (e) {
                            // Set error to ease access later.
                            instance.error = e;

                            // There was a connection error of some sort
                            // Hide the inline search spinner
                            instance.searchSpinner.hide();

                            // Fire an event to notify users of an error
                            instance.events.trigger("request-error", e);

                            // Enable all mapped fields to allow manual entry.
                            var mapFn = function (e) {
                                if ($(this).is('input') || $(this).is('text') || $(this).is('select')) {
                                    $(this).attr('disabled', false).show();
                                }
                            };

                            for (var mappingIndex = 0; mappingIndex < instance.resultMapping.length; mappingIndex++) {
                                var mappingObj = instance.resultMapping[mappingIndex];
                                $(mappingObj.selector).each(mapFn);
                            }

                            // Populate picklist
                            _callBack({
                                hasOverride: false,
                                results: [{
                                    suggestion: e,
                                    format: '',
                                    error: e,
                                    matched: []
                                }]
                            });

                            // Automatically hide error after 5 seconds.
                            if (instance.picklist._hideTimer) {
                                clearTimeout(instance.picklist._hideTimer);
                            }

                            instance.picklist._hideTimer = setTimeout(instance.picklist.hide, instance.hideDelay);
                        });
                    }
                } else if (instance.lastSearchTerm !== instance.currentSearchTerm) {
                    // Clear the picklist if the search term is cleared/empty
                    instance.picklist.hide();
                }
            }, instance.searchDelay);
        };

        instance.setCountryList = function () {
            instance.countryList = instance.elements.countryList;

            // If the user hasn't passed us a country list, then create new list?
            if (!instance.countryList) {
                instance.createCountryDropdown();
            }
        };

        // Determine whether searching is currently permitted
        instance.canSearch = function () {
            // If searching on this instance is enabled, and
            return (instance.enabled &&
                    // If search term is not empty, and
                    instance.currentSearchTerm !== "" &&
                    // If the country is not empty
                    instance.countryList.value !== undefined && instance.countryList.value !== "");
        };

        //Determine whether tab key was pressed
        instance.checkTab = function (event) {
            var e = event || window.event;
            e = e.which || e.keyCode;
            if (e === 9 /*Tab*/) {
                var bubbleEvent = instance.picklist.keyup(e);
                if (typeof bubbleEvent === 'boolean' && !bubbleEvent) {
                    return false;
                }

                return true;
            }
        };

        instance.createCountryDropdown = function () {
            // What countries?
            // Where to position it?
            instance.countryList = {};
        };

        // Get a final (Formatted) address
        instance.format = function (url) {
            // Trigger an event
            instance.events.trigger("pre-formatting-search", url);

            // Hide the searching spinner
            instance.searchSpinner.hide();

            // Construct the format URL (append the token)
            instance.currentFormatUrl = ContactDataServices.urls.construct.address.format(url, instance);

            // Initiate a new Format request
            var _callBack = instance.result.show;
            var client = new EXP.DQ.Address.Client({ token: instance.token, errorText: instance.errorText });
            client.format(url, instance.currentCountryCode, instance.layout)
                .done(function (result) {
                var r = {};
                r.address = [];
                for (var rIndex in result.address) {
                    var lineObject = {};
                    lineObject[rIndex] = result.address[rIndex];
                    r.address.push(lineObject);
                }
                _callBack(r);
            })
                .fail(function (e) {
                // Set error to ease access later.
                instance.error = e;

                // There was a connection error of some sort
                // Hide the inline search spinner
                instance.searchSpinner.hide();

                // Fire an event to notify users of an error
                instance.events.trigger("request-error", e);
            })
                .always(function (e) {

                var mapFn = function (e) {
                    if ($(this).is('input') || $(this).is('text') || $(this).is('select')) {
                        //$(this).attr('disabled', false).show();
                    }
                };


                // Enable all fields anyhow to allow manual entry.
                for (var mappingIndex = 0; mappingIndex < instance.resultMapping.length; mappingIndex++) {

                    var mappingObj = instance.resultMapping[mappingIndex];
                    $(mappingObj.selector).each(mapFn);
                }
            });

        };

        instance.picklist = {
            // Set initial size
            size: 0,
            // Set initial max size
            maxSize: 25,
            // Render a picklist of search results
            show: function (items) {
                // Store the picklist items
                instance.picklist.items = items.results;

                // Reset any previously selected current item
                instance.picklist.currentItem = null;

                // Update picklist size
                instance.picklist.size = instance.picklist.items.length;

                // Get/Create picklist container element
                instance.picklist.list = instance.picklist.list || instance.picklist.createList();

                // Ensure previous results are cleared
                instance.picklist.list.innerHTML = "";

                // Reset the picklist tab count (used for keyboard navigation)
                instance.picklist.resetTabCount();

                // Hide the inline search spinner
                instance.searchSpinner.hide();

                // Prepend an option for "use address entered"
                if (items.hasOverride) {
                    instance.picklist.useAddressEntered.destroy();
                    instance.picklist.useAddressEntered.element = instance.picklist.useAddressEntered.create(items.override);
                } else {
                    if (instance.picklist.useAddressEntered) {
                        instance.picklist.useAddressEntered.destroy();
                    }
                }

                // Fire an event before picklist is created
                instance.events.trigger("pre-picklist-create", instance.picklist.items);

                if (instance.picklist.size > 0) {
                    // Iterate over and show results
                    instance.picklist.items.forEach(function (item) {
                        // Create a new item/row in the picklist
                        var listItem = instance.picklist.createListItem(item);
                        instance.picklist.list.appendChild(listItem);

                        // Listen for selection on this item
                        instance.picklist.listen(listItem);
                    });
                } else {
                    // No results found.
                    // Show a friendly assistance message to help user search.
                    // Feature to allow customized message if search length is less than allowed.
                    var text = '';
                    if (instance.currentSearchTerm.length < instance.searchTermMinLength) {
                        text = instance.continueTypingText;
                    } else {
                        // Feature to allow customized message if search has no result.
                        text = instance.notFoundText;
                    }

                    var listItem = instance.picklist.createListItem({
                        suggestion: text,
                        format: ''
                    });

                    instance.picklist.list.appendChild(listItem);
                }

                // Fire an event after picklist is created
                instance.events.trigger("post-picklist-create");
            },
            // Remove the picklist
            hide: function () {
                // Clear the current picklist item
                instance.picklist.currentItem = null;
                // Remove the "use address entered" option too
                instance.picklist.useAddressEntered.destroy();
                // Remove the main picklist container
                if (instance.picklist.list) {
                    instance.input.parentNode.removeChild(instance.picklist.container);
                    instance.picklist.list = undefined;
                }
            },
            useAddressEntered: {
                // Create a "use address entered" option
                create: function (item) {
                    var listItem = instance.picklist.createListItem(item);
                    if (item.format) {
                        listItem.setAttribute('override', true);
                        $(listItem).addClass("use-address-entered");
                        instance.picklist.list.parentNode.insertBefore(listItem, instance.picklist.list.nextSibling);
                        instance.picklist.listen(listItem);
                    } else {
                        $(listItem).addClass("use-address-entered");
                        instance.picklist.list.parentNode.insertBefore(listItem, instance.picklist.list.nextSibling);
                        $(listItem).on("click", instance.picklist.useAddressEntered.click);
                        return listItem;
                    }

                    return listItem;
                },
                // Destroy the "use address entered" option
                destroy: function () {
                    if (instance.picklist.useAddressEntered.element) {
                        instance.picklist.list.parentNode.removeChild(instance.picklist.useAddressEntered.element);
                        instance.picklist.useAddressEntered.element = undefined;
                    }
                },
                // Use the address entered as the Formatted address
                click: function (item) {

                    var inputData = {
                        address: []
                    };

                    if (instance.currentSearchTerm) {
                        // Try and split into lines by using comma delimiter
                        var lines = instance.currentSearchTerm.split(",");
                        if (lines.length > 0) {
                            for (var i = 0; i < lines.length; i++) {
                                inputData.address.push(instance.picklist.useAddressEntered.formatManualAddressLine(lines, i));
                            }
                        }

                        // Pad with additional blank fields if needed
                        var maxLines = 7;
                        var additionalLinesNeeded = maxLines - lines.length;
                        if (additionalLinesNeeded > 0) {
                            var counterStart = maxLines - additionalLinesNeeded;
                            for (var j = counterStart; j < maxLines; j++) {
                                inputData.address.push(instance.picklist.useAddressEntered.formatManualAddressLine([], j));
                            }
                        }
                    }

                    instance.result.show(inputData);
                    instance.result.updateHeading(instance.formattedAddressContainer.manualHeadingText);


                },
                // Create and return an address line object with the key as the label
                formatManualAddressLine: function (lines, i) {
                    var key = ContactDataServices.defaults.addressLineLabels[i];
                    var lineObject = {};
                    lineObject[key] = lines[i] || "";
                    return lineObject;
                }
            },
            // Create the picklist list (and container) and inject after the input
            createList: function () {
                var container = document.createElement("div");
                $(container).addClass("address-picklist-container");
                // Insert the picklist container after the input
                instance.input.parentNode.insertBefore(container, instance.input.nextSibling);
                instance.picklist.container = container;

                var list = document.createElement("div");
                $(list).addClass("address-picklist");

                // Append the picklist to the container
                container.appendChild(list);

                $(list).on("keydown", instance.picklist.enter);
                return list;
            },
            // Create a new picklist item/row
            createListItem: function (item) {
                var row = document.createElement("div");
                row.innerHTML = instance.picklist.addMatchingEmphasis(item);

                // Store the Format URL
                row.setAttribute("format", item.format);

                // Add class "info" to informational picklist item.
                if (!item.format || item.format.length < 1) {
                    $(row).addClass('info');
                }

                // Add class 'error' if contains error.
                if (item.error) {
                    $(row).addClass('error');
                }

                return row;
            },
            // Tab count used for keyboard navigation
            tabCount: -1,
            resetTabCount: function () {
                instance.picklist.tabCount = -1;
            },
            // Keyboard navigation
            keyup: function (e) {
                if (instance.error) {
                    // Allow bubble event if there's an error.'
                    return;
                }

                if (!instance.picklist.list) {
                    return;
                }

                if (e === 13/*Enter*/) {
                    instance.picklist.checkEnter();
                    return false;
                }

                // Get a list of all the addresses in the picklist
                var addresses = instance.picklist.list.querySelectorAll("div"),
                    firstAddress, lastAddress;

                // If the picklist is empty, just return
                if (addresses.length === 0) {
                    return;
                }

                // Set the tabCount based on previous and direction
                if (e === 38/*Up*/) {
                    instance.picklist.tabCount--;
                }
                else {
                    instance.picklist.tabCount++;
                }

                // Set top and bottom positions and enable wrap-around
                if (instance.picklist.tabCount < 0) {
                    instance.picklist.tabCount = addresses.length - 1;
                    lastAddress = true;
                }
                if (instance.picklist.tabCount > addresses.length - 1) {
                    instance.picklist.tabCount = 0;
                    firstAddress = true;
                }

                // Highlight the selected address
                var currentlyHighlighted = addresses[instance.picklist.tabCount];
                // Remove any previously highlighted ones
                var previouslyHighlighted = instance.picklist.list.querySelector(".selected");
                if (previouslyHighlighted) {
                    $(previouslyHighlighted).removeClass("selected");
                }
                $(currentlyHighlighted).addClass("selected");
                // Set the currentItem on the picklist to the currently highlighted address
                instance.picklist.currentItem = currentlyHighlighted;

                // Scroll address into view, if required
                var addressListCoords = {
                    top: instance.picklist.list.offsetTop,
                    bottom: instance.picklist.list.offsetTop + instance.picklist.list.offsetHeight,
                    scrollTop: instance.picklist.list.scrollTop,
                    selectedTop: currentlyHighlighted.offsetTop,
                    selectedBottom: currentlyHighlighted.offsetTop + currentlyHighlighted.offsetHeight,
                    scrollAmount: currentlyHighlighted.offsetHeight
                };
                if (firstAddress) {
                    instance.picklist.list.scrollTop = 0;
                }
                else if (lastAddress) {
                    instance.picklist.list.scrollTop = 999;
                }
                else if (addressListCoords.selectedBottom + addressListCoords.scrollAmount > addressListCoords.bottom) {
                    instance.picklist.list.scrollTop = addressListCoords.scrollTop + addressListCoords.scrollAmount;
                }
                else if (addressListCoords.selectedTop - addressListCoords.scrollAmount - addressListCoords.top < addressListCoords.scrollTop) {
                    instance.picklist.list.scrollTop = addressListCoords.scrollTop - addressListCoords.scrollAmount;
                }

                // Any key press focus back to the search field, and prevent default behavior (tab focus away) or submit form.
                $(instance.input).focus();
                return false;
            },
            // Add emphasis to the picklist items highlighting the match
            addMatchingEmphasis: function (item) {
                var highlights = item.matched || [],
                    label = item.suggestion;

                for (var i = 0; i < highlights.length; i++) {
                    var replacement = '<b>' + label.substring(highlights[i][0], highlights[i][1]) + '</b>';
                    label = label.substring(0, highlights[i][0]) + replacement + label.substring(highlights[i][1]);
                }

                return label;
            },
            // Listen to a picklist selection
            listen: function (row) {
                $(row).on("click", instance.picklist.pick.bind(null, row));
            },
            checkEnter: function () {
                var picklistItem;
                // If picklist contains 1 address then use this one to format
                if (instance.picklist.size === 1) {
                    picklistItem = instance.picklist.list.querySelectorAll("div")[0];
                } // Else use the currently highlighted one when navigation using keyboard
                else if (instance.picklist.currentItem) {
                    picklistItem = instance.picklist.currentItem;
                }
                if (picklistItem) {
                    instance.picklist.pick(picklistItem);
                }
            },
            // How to handle a picklist selection
            pick: function (item) {
                // Fire an event when an address is picked
                instance.events.trigger("post-picklist-selection", item);

                var mapFn = function (e) {
                    // Do nothing for input used for search.
                    if (this === instance.elements.input) {
                        return;
                    }

                    if ($(this).is('input') || $(this).is('text') || $(this).is('select')) {
                        $(this).attr('disabled', false);
                        $(this).parents('.mktoFormRow').attr('style','visibility: visible; position: static;');
                        $(this).attr('readonly', false);
                    }

                    if($(this).attr('id')=='State'){
                        $(this).hide();
                        $(this).attr('disabled', true);
                        $('select.State').attr('disabled', false);
                        //						$('select.State').attr('style','visibility: visible; position: static;');
                        $('select.State').show();
                    }

                    if($(this).attr('id')=='Country'){
                        $(this).hide();
                        //						$(this).attr('style','visibility: hidden; position: absolute;');
                        $(this).attr('disabled', true);
                        $('select.Country').attr('disabled', false);
                        $('select.Country').show();
                        //						$('select.Country').attr('style','visibility: visible; position: static;');
                    }

                };

                var mapFnRemove = function (e) {
                    // Do nothing for input used for search.
                    if (this === instance.elements.input) {
                        return;
                    }

                    if ($(this).is('input') || $(this).is('text') || $(this).is('select')) {
                        $(this).parents('.mktoFormRow').attr('style','visibility: visible; position: static;');

                        $(this).attr('disabled', false);
                        $(this).attr('readonly', true);
                    }

                    if($(this).attr('id')=='State'){
                        $('select.State').hide();
                        //						$(this).attr('style','visibility: visible; position: static;');
                        $(this).attr('disabled', false);
                        //						$(this).attr('style','visibility: hidden; position: absolute;');
                        $(this).show();
                        $('select.State').attr('disabled', true);
                        //						$('select.State').attr('style','visibility: hidden; position: absolute;');
                    }

                    if($(this).attr('id')=='Country'){
                        $('select.Country').hide();
                        //						$(this).attr('style','visibility: visible; position: static;');
                        $(this).attr('disabled', false);

                        $(this).show();
                        $('select.Country').attr('disabled', true);
                        //						$('select.Country').attr('style','visibility: hidden; position: absolute;');
                    }

                };

                //If manual entry address, lets show fields

                if($(item).hasClass("use-address-entered")){

                    // Disable all inputs not used for search.
                    for (var mappingIndex = 0; mappingIndex < instance.resultMapping.length; mappingIndex++) {
                        var mappingObj = instance.resultMapping[mappingIndex];
                        $(mappingObj.selector).each(mapFn);
                    }

                }else{
                    for (var mappingIndex = 0; mappingIndex < instance.resultMapping.length; mappingIndex++) {
                        var mappingObj = instance.resultMapping[mappingIndex];
                        $(mappingObj.selector).each(mapFnRemove);
                    }
                }


                // Get a final address using picklist item
                instance.format(item.getAttribute("format"));
            }
        };

        instance.result = {
            // Render a Formatted address
            show: function (data) {

                // Hide the inline search spinner
                instance.searchSpinner.hide();

                // Hide the picklist
                instance.picklist.hide();

                if (instance.input) {
                    $(instance.input).val('');
                }

                if (data.address.length > 0) {
                    // Fire an event to say we've got the formatted address
                    instance.events.trigger("post-formatting-search", data);

                    // Create an array to hold the hidden input fields
                    var inputArray = [];

                    // Loop over each formatted address component
                    for (var i = 0; i < data.address.length; i++) {
                        var addressComponent = data.address[i];
                        // The addressComponent object will only have one property, but we don't know the key
                        for (var key in addressComponent) {
                            if (addressComponent.hasOwnProperty(key)) {
                                // Create an input to store the address line
                                var label = instance.result.createAddressLine.label(key);
                                inputArray.push(instance.result.createAddressLine.input(label, addressComponent[key]));
                            }
                        }
                    }

                    // Hide country and address search fields
                    instance.result.hideSearchInputs();

                    // Get formatted address container element
                    if (typeof instance.formattedAddressContainer !== 'undefined' && instance.formattedAddressContainer !== null) {
                        instance.result.formattedAddressContainer = instance.elements.formattedAddressContainer || instance.result.createFormattedAddressContainer();
                    }

                    if (isElement(instance.result.formattedAddressContainer)) {
                        // Create an (optional) heading for the formattedAddressContainer
                        instance.result.createHeading();

                        // Write the list of hidden address line inputs to the DOM in one go
                        instance.result.renderInputList(inputArray);

                        // Write the 'Search again' link and insert into DOM
                        instance.result.createSearchAgainLink();
                    }

                    // Result formatter.
                    var helper = new ContactDataServices.result(data);

                    // Map address component to ampped fields.
                    // Input/Text/Select elements will be populated with values of result.
                    // Other elements will replace html data. 
                    for (var mappingIndex = 0; mappingIndex < instance.resultMapping.length; mappingIndex++) {
                        var mappingObj = instance.resultMapping[mappingIndex];
                        var mappedValue = helper.parse(mappingObj.format);
                        $(mappingObj.selector).each(helper.fnSetValue(mappedValue));
                    }
                }

                //remove empty class

                $(".mktoForm input[type=text], .mktoForm select").each(function(){
                    if($(this).val()!= ""){
                        $(this).removeClass('empty');
                        //macquarieForm.generalValidation(this);
                    }else{
                        $(this).addClass('empty');
                        macquarieForm.makeNeutral($(this).attr('id'));
                    }
                });


            },
            hide: function () {
                if (isElement(instance.result.formattedAddressContainer)) {
                    instance.input.parentNode.removeChild(instance.result.formattedAddressContainer);
                    instance.result.formattedAddressContainer = undefined;
                }
            },
            // Create the formatted address container and inject after the input
            createFormattedAddressContainer: function () {
                var container = document.createElement("div");
                $(container).addClass("formatted-address");

                // Insert the container after the input
                instance.input.parentNode.insertBefore(container, instance.input.nextSibling);
                return container;
            },
            // Create a heading for the formatted address container
            createHeading: function () {
                // Create a heading for the formatted address
                if (isElement(instance.result.formattedAddressContainer)) {
                    if (instance.formattedAddressContainer.showHeading) {
                        var heading = document.createElement(instance.formattedAddressContainer.headingType);
                        heading.innerHTML = instance.formattedAddressContainer.validatedHeadingText;
                        instance.result.formattedAddressContainer.appendChild(heading);
                    }
                }
            },
            // Update the heading text in the formatted address container
            updateHeading: function (text) {
                //Change the heading text to "Manual address entered"
                if (instance.formattedAddressContainer.showHeading) {
                    var heading = instance.result.formattedAddressContainer.querySelector(instance.formattedAddressContainer.headingType);
                    heading.innerHTML = text;
                }
            },
            createAddressLine: {
                // Create an input to store the address line
                input: function (key, value) {
                    // Create a wrapper
                    var div = document.createElement("div");
                    $(div).addClass("address-line-input");

                    // Create the label
                    var label = document.createElement("label");
                    label.innerHTML = key.replace(/([A-Z])/g, ' $1') //Add space before capital Letters
                        .replace(/([0-9])/g, ' $1') //Add space before numbers
                        .replace(/^./, function (str) { return str.toUpperCase(); }); //Make first letter of word a capital letter
                    div.appendChild(label);

                    // Create the input
                    var input = document.createElement("input");
                    input.setAttribute("type", "text");
                    input.setAttribute("name", key);
                    input.setAttribute("value", value);
                    div.appendChild(input);
                    return div;
                },
                // Create the address line label based on the country and language
                label: function (key) {
                    var label = key;
                    var language = instance.language;
                    var country = instance.currentCountryCode;
                    var translations = ContactDataServices.translations;
                    if (translations) {
                        try {
                            var translatedLabel = translations[language][country][key];
                            if (translatedLabel) {
                                label = translatedLabel;
                            }
                        } catch (e) {
                            // Translation doesn't exist for key
                        }
                    }
                    return label;
                }
            },
            // Create the 'Search again' link that resets the search
            createSearchAgainLink: function () {
                if (instance.searchAgain.visible) {
                    var link = document.createElement("a");
                    link.setAttribute("href", "#");
                    $(link).addClass("search-again-link");
                    link.innerHTML = instance.searchAgain.text;
                    // Insert into the formatted address container
                    instance.result.formattedAddressContainer.appendChild(link);
                    // Bind event listener
                    $(link).on("click", instance.reset);
                }
            },
            // Write the list of hidden address line inputs to the DOM
            renderInputList: function (inputArray) {
                if (inputArray.length > 0) {
                    for (var i = 0; i < inputArray.length; i++) {
                        instance.result.formattedAddressContainer.appendChild(inputArray[i]);
                    }
                }
            },
            // Hide the initial country and address search inputs
            hideSearchInputs: function () {
                instance.toggleVisibility(instance.input.parentNode);
            }
        };

        // Toggle the visibility of elements
        instance.toggleVisibility = function (scope) {
            scope = scope || document;
            var elements = scope.querySelectorAll(".toggle");
            for (var i = 0; i < elements.length; i++) {
                $(elements[i]).toggle();
            }
        };

        instance.searchSpinner = {
            show: function () {
                // Return if we're not displaying a spinner
                if (!instance.useSpinner) {
                    return;
                }

                // If spinner found, show.
                var spinner = instance.input.parentNode.querySelector(".loader-inline");
                if (spinner) {
                    $(spinner).show();
                    return;
                }

                // Create the spinner container
                var spinnerContainer = document.createElement("div");
                $(spinnerContainer).addClass("loader");
                $(spinnerContainer).addClass("loader-inline");

                // Create the spinner
                spinner = document.createElement("div");
                $(spinner).addClass("spinner");
                spinnerContainer.appendChild(spinner);

                // Insert the spinner after the field
                instance.input.parentNode.insertBefore(spinnerContainer, instance.input.nextSibling);
            },

            hide: function () {
                // Return if we're not displaying a spinner
                if (!instance.useSpinner) {
                    return;
                }

                var spinner = instance.input.parentNode.querySelector(".loader-inline");
                if (spinner) {
                    $(spinner).hide();
                }

            }
        };

        // Reset the search
        instance.reset = function (event) {
            if (event) {
                event.preventDefault();
            }
            // Enable searching
            instance.enabled = true;
            // Hide formatted address
            instance.result.hide();
            // Show search input
            instance.toggleVisibility(instance.input.parentNode);
            // Apply focus to input
            instance.input.focus();

            // Fire an event after a reset
            instance.events.trigger("post-reset");
        };

        // How to handle request errors
        instance.handleError = {
            // How to handle 400 Bad Request
            badRequest: function (xhr) {
                instance.enabled = false;

                // As searching is disabled, show button to render final address instead
                instance.handleError.showSubmitButton();

                // Fire an event to notify users of the error
                instance.events.trigger("request-error-400", xhr);
            },

            // How to handle 401 Unauthorized (invalid token?) requests
            unauthorized: function (xhr) {
                instance.enabled = false;

                // As searching is disabled, show button to render final address instead
                instance.handleError.showSubmitButton();

                // Fire an event to notify users of the error
                instance.events.trigger("request-error-401", xhr);
            },

            // How to handle 403 Forbidden requests
            forbidden: function (xhr) {
                instance.enabled = false;

                // As searching is disabled, show button to render final address instead
                instance.handleError.showSubmitButton();

                // Fire an event to notify users of the error
                instance.events.trigger("request-error-403", xhr);
            },

            // How to handle 404 Not Found requests
            notFound: function (xhr) {
                instance.enabled = false;

                // As searching is disabled, show button to render final address instead
                instance.handleError.showSubmitButton();

                // Fire an event to notify users of the error
                instance.events.trigger("request-error-404", xhr);
            },

            // As searching is disabled, show button to render final address instead
            showSubmitButton: function () {
                var button = document.createElement("button");
                button.innerText = "Submit";
                instance.input.parentNode.insertBefore(button, instance.input.nextSibling);
                $(button).on("click", function () {
                    // Simulate a manual "use address entered" entry
                    instance.picklist.useAddressEntered.click();
                    // Remove the button
                    instance.input.parentNode.removeChild(button);
                });
            }
        };

        // Use this to initiate and track XMLHttpRequests
        instance.request = {
            currentRequest: null,
            get: function (url, callback) {
                instance.request.currentRequest = new XMLHttpRequest();
                instance.request.currentRequest.open('GET', url, true);
                instance.request.currentRequest.timeout = 5000; // 5 seconds

                instance.request.currentRequest.onload = function (xhr) {
                    if (instance.request.currentRequest.status >= 200 && instance.request.currentRequest.status < 400) {
                        // Success!
                        var data = JSON.parse(instance.request.currentRequest.responseText);
                        callback(data);
                    } else {
                        // We reached our target server, but it returned an error
                        instance.searchSpinner.hide();

                        // Fire an event to notify users of an error
                        instance.events.trigger("request-error", xhr);

                        // If the request is 400 Bad Request
                        if (instance.request.currentRequest.status === 400) {
                            instance.handleError.badRequest(xhr);
                        }
                        // If the request is 401 Unauthorized (invalid token) we should probably disable future requests
                        else if (instance.request.currentRequest.status === 401) {
                            instance.handleError.unauthorized(xhr);
                        }
                        // If the request is 403 Forbidden
                        else if (instance.request.currentRequest.status === 403) {
                            instance.handleError.forbidden(xhr);
                        }
                        // If the request is 404 Not Found
                        else if (instance.request.currentRequest.status === 404) {
                            instance.handleError.notFound(xhr);
                        }
                    }
                };

                instance.request.currentRequest.onerror = function (xhr) {
                    // There was a connection error of some sort
                    // Hide the inline search spinner
                    instance.searchSpinner.hide();

                    // Fire an event to notify users of an error
                    instance.events.trigger("request-error", xhr);
                };

                instance.request.currentRequest.ontimeout = function (xhr) {
                    // There was a connection timeout
                    // Hide the inline search spinner
                    instance.searchSpinner.hide();

                    // Fire an event to notify users of the timeout
                    instance.events.trigger("request-timeout", xhr);
                };

                instance.request.currentRequest.send();
            }
        };

        // Initialise this instance of ContactDataServices
        instance.init();

        // Return the instance object to the invoker
        return instance;
    };

    ContactDataServices.result = function (result) {
        function buildResult(data) {
            var d = {};
            for (var addressLine in data.address) {
                if (!data.address.hasOwnProperty(addressLine)) {
                    continue;
                }

                for (var addressLineKey in data.address[addressLine]) {
                    if (!data.address[addressLine].hasOwnProperty(addressLineKey)) {
                        continue;
                    }

                    var addressValue = data.address[addressLine][addressLineKey];
                    d[addressLineKey] = addressValue;
                }
            }

            return d;
        }

        this.parse = function (format) {
            var replaceKvps = {};
            var keys = format.match(/\{\{([-a-zA-Z0-9]+)\}\}/ig);
            for (var kIndex in keys) {
                if (!keys.hasOwnProperty(kIndex)) {
                    continue;
                }

                var keyWithoutBraces = keys[kIndex].replace("{{", "").replace("}}", "");
                replaceKvps[keys[kIndex]] = typeof layoutValues[keyWithoutBraces] === 'string' ? layoutValues[keyWithoutBraces] : '';
            }

            for (var k in replaceKvps) {
                if (!replaceKvps.hasOwnProperty(k)) {
                    continue;
                }

                format = format.replace(k, replaceKvps[k]);
            }

            // Remove spaces between words, and consecutive commas
            format = format.replace(/\s+/g, ' ')    // remove consecutive spaces
                .replace(/\s*,+\s*/g, ',')          // remove spaces before and after commas
                .replace(/,+/g, ', ')               // remove consecutive commas
                .replace(/,+\s*$/g, '');            // remove trailing commas 

            return format.trim();
        };

        this.fnSetValue = function (mappedValue) {
            return function (e) {
                if ($(this).is('input') || $(this).is('text') || $(this).is('select')) {
                    if ($(this).val() !== mappedValue) {
                        $(this).val(mappedValue);

                        if($(this).attr('id')=='State'){

                            if($(this).val()!== ""){
                                $('select.State').val($(this).val());
                            }
                        }

                        //$(this).addClass('empty');
                    }else{
                        if($(this).attr('id')=='State'){
                            $('select.State').val('NSW');
                        }
                    }
                } else {
                    if ($(this).html() !== mappedValue) {
                        $(this).html(mappedValue);
                    }
                }
            };
        };

        var layoutValues = buildResult(result);
    };

})(window, window.document);

var EXP = EXP || {};
EXP.DQ = EXP.DQ || {};

EXP.DQ.Email = (function (lib, dq, undefined) {
    var headersTemplate = {
        "Content-Type": "application/json"
    };

    //Helper function
    function checkStatus() {
        if (!dq.Urls[EXP.DQ.UrlEnum.EMAIL]) {
            throw "Unable to search, missing url.";
        }
        if (!dq.Tokens[EXP.DQ.TokenEnum.EMAIL]) {
            throw "Unable to search, missing token.";
        }
        return true;
    }

    lib.Client = function Client(options) {
        var _self = this;

        // Options
        this.options = {
            restUrl: 'https://api.experianmarketingservices.com/sync/queryresult/EmailValidate/1.0/'
        };

        // Override options
        options = options === undefined ? {} : options;
        $.extend(_self.options, options === undefined || options);

        this.validate = function (email) {
            EXP.DQ.addUrl(EXP.DQ.UrlEnum.EMAIL, _self.options.restUrl);
            EXP.DQ.addToken(EXP.DQ.TokenEnum.EMAIL, _self.options.token);

            return lib.validateEmail(email);
        };
    };

    lib.validateEmail = function (emailToValidate) {
        var dfd = $.Deferred();
        try {
            checkStatus();

            var headers = headersTemplate;
            headers['Auth-Token'] = dq.Tokens[EXP.DQ.TokenEnum.EMAIL];
            var content = JSON.stringify({ "email": emailToValidate });
            var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.EMAIL, headers, content, EXP.DataTypeEnum.JSON);

            promise.done(function (rJ) {
                var searchResult = new lib.EmailSearchResult(rJ.Certainty, rJ.Email, rJ.Message, rJ.Corrections);
                dfd.resolve(searchResult);
            }).fail(function (result) {
                dfd.reject("Failed to communicate with email validation service");
            });
        } catch (e) {
            dfd.reject(e);
        }

        return dfd.promise();
    };

    //Classes
    lib.EmailSearchResult = function EmailSearchResult(certainty, email, message, corrections) {
        this.certainty = certainty;
        this.email = email;
        this.message = message;
        this.suggestions = corrections;
    };
    lib.EmailSearchResult.prototype.getCertainty = function () {
        return this.certainty;
    };
    lib.EmailSearchResult.prototype.getEmail = function () {
        return this.email;
    };
    lib.EmailSearchResult.prototype.isOk = function () {
        return (this.message === 'OK');
    };
    lib.EmailSearchResult.prototype.getSuggestions = function () {
        return this.suggestions;
    };
    lib.EmailSearchResult.prototype.hasSuggestions = function () {
        return this.suggestions && this.suggestions.length > 0;
    };
    lib.EmailSearchResult.prototype.getSuggestionsCount = function () {
        if (this.suggestions) {
            return this.suggestions.length;
        } else {
            return 0;
        }
    };

    return lib;

} (EXP.DQ.Email || {}, EXP.DQ));

var EXP = EXP || {};
EXP.DQ = EXP.DQ || {};

EXP.DQ.Mobile = (function (lib, dq, undefined) {

    var headersTemplate = {
        "Content-Type": "application/json"
    };

    //Helper function
    function checkStatus() {
        if (!dq.Urls[EXP.DQ.UrlEnum.MOBILE]) {
            throw "Unable to search, missing url.";
        }
        if (!dq.Tokens[EXP.DQ.TokenEnum.MOBILE]) {
            throw "Unable to search, missing token.";
        }
        return true;
    }

    /**
     * A mobile number validation service client.
     */
    lib.Client = function (options) {
        var _self = this;

        _self.options = {
            restUrl: 'https://api.experianmarketingservices.com/sync/queryresult/PhoneValidate/3.0/'
        };

        options = options === undefined ? {} : options;
        $.extend(_self.options, options);

        _self.validate = function (mobileNumber) {
            EXP.DQ.addUrl(EXP.DQ.UrlEnum.MOBILE, _self.options.restUrl);
            EXP.DQ.addToken(EXP.DQ.TokenEnum.MOBILE, _self.options.token);

            return EXP.DQ.Mobile.validateMobile(mobileNumber);
        };
    };

    lib.validateMobile = function (mobileToValidate) {
        var dfd = $.Deferred();
        try {
            checkStatus();

            var headers = headersTemplate;
            headers['Auth-Token'] = dq.Tokens[EXP.DQ.TokenEnum.MOBILE];
            var content = JSON.stringify({ "Number": mobileToValidate });
            var promise = dq.callWebService(EXP.MethodEnum.POST, EXP.DQ.UrlEnum.MOBILE, headers, content, EXP.DataTypeEnum.JSON);
            promise.done(function (rJ) {
                var searchResult = new lib.MobileSearchResult(rJ.Certainty, rJ.Number, rJ.ResultCode, rJ.PhoneType, rJ.AdditionalPhoneInfo);
                dfd.resolve(searchResult);
            }).fail(function (result) {
                dfd.reject("Failed to communicate with phone validation service");
            });
        } catch (e) {
            dfd.reject(e);
        }

        return dfd.promise();
    };

    //Classes
    lib.MobileSearchResult = function MobileSearchResult(certainty, number, resultCode, phoneType, additionalPhoneInfo) {
        this.certainty = certainty;
        this.number = number;
        this.resultCode = resultCode;
        this.phoneType = phoneType;
        this.additionalPhoneInfo = additionalPhoneInfo;
    };
    lib.MobileSearchResult.prototype.getCertainty = function () {
        return this.certainty;
    };
    lib.MobileSearchResult.prototype.getNumber = function () {
        return this.number;
    };
    lib.MobileSearchResult.prototype.getResultCode = function () {
        return this.resultCode;
    };
    lib.MobileSearchResult.prototype.getPhoneType = function () {
        return this.phoneType;
    };
    lib.MobileSearchResult.prototype.isVerified = function () {
        return (this.certainty === 'Verified');
    };
    lib.MobileSearchResult.prototype.isAbsent = function () {
        return (this.certainty === 'Absent');
    };
    return lib;

} (EXP.DQ.Mobile || {}, EXP.DQ));


var EDQ = EDQ || {};
EDQ.sample = EDQ.sample || {};
EDQ.sample.token = '3584e022-e5d7-4e87-82e1-65820abeedf9';

export {EXP, EDQ}