import { store } from '../../store/store.js';

/**
 * InitInput
 */
export default class InitFormInput{

	constructor(parentSelector=''){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			input: 		$(`${parentSelector}.form__input`),
		};

		/**
		 * Initalise Class Functions
		//  */

		if(!this.cache.input.hasClass(store.defaultClasses.initialise)) {
			this.setupInputState();
			this.handleInputFocus();
			this.handleInputBlur();
			this.handleInputChange();
		}
	}

	setupInputState(){
		this.cache.input.find('input, textarea, input:not([type="checkbox"]), input:not([type="radio"])').each(function(index, self){
			$(self).addClass(store.defaultClasses.initialise);
			if($(self).val().length > 0){
				$(self).addClass(store.defaultClasses.active);
			}
		}.bind(this));
	}

	/**
	 * @property {Function} handleInputFocus  - Description Here
	 * @returns void
	 */
	handleInputFocus(){
		this.cache.input.find('input, textarea, input:not([type="checkbox"]), input:not([type="radio"])').focus(function(e){
			$(e.target).addClass(store.defaultClasses.active);
		}.bind(this));
	}

	/**
	 * @property {Function} handleInputBlur  - Description Here
	 * @returns void
	 */
	handleInputBlur(){
		this.cache.input.find('input, textarea, input:not([type="checkbox"]), input:not([type="radio"])').blur(function(e){
			const $_this = $(e.target);

			setTimeout(function(){
				$_this.val() === "" && $_this.removeClass(store.defaultClasses.active);
			}, 200);

		}.bind(this));
	}

    handleInputChange(){
        this.cache.input.find('input, textarea, input:not([type="checkbox"]), input:not([type="radio"])').change(function(e){
			$(e.target).addClass(store.defaultClasses.active);
		}.bind(this));
    }
}
