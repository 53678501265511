import { store } from '../../store/store.js';
import anime from 'animejs/lib/anime.es.js';

export default class HomepageAnime{

    //----------------------
    // Initialise functions
    //----------------------

	constructor(){
        this.functionalityAnimate();
        this.simplicityAnimate();
        this.consistencyAnimate();
        this.collaborationAnimate();
        this.evolutionAnimate();
	}

    //------------------------------------
    // Handle animation for functionality
    //------------------------------------

    functionalityAnimate(){
        $('#functionality').hover(function(){
            anime ({
                targets: '.wrench',
                scale: '1.1',
                rotate: '-90',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 20, 5)',
                duration: 1000
            });
            anime ({
                targets: '.tool',
                scale: '1.1',
                rotate: '90',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 20, 5)',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.wrench, .tool',
                scale: '1',
                rotate: '0',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 20, 5)',
                duration: 1000
            });
        })
    }

    //---------------------------------
    // Handle animation for simplicity
    //---------------------------------

    simplicityAnimate(){
        $('#simplicity').hover(function(){
            anime ({
                targets: '.square',
                scale: '1.1',
                borderRadius: ['0%', '50%'],
                easing: 'easeInOutExpo',
                duration: 1000
            });
            anime ({
                targets: '.circle',
                scale: '1.1',
                borderRadius: ['50%', '0%'],
                easing: 'easeInOutExpo',
                duration: 1000
            });
        }, function (){
            anime ({
                targets: '.square',
                scale: '1',
                borderRadius: ['50%', '0%'],
                easing: 'easeInOutExpo',
                duration: 1000
            });
            anime ({
                targets: '.circle',
                scale: '1',
                borderRadius: ['0%', '50%'],
                easing: 'easeInOutExpo',
                duration: 1000
            });
        })
    }

    //----------------------------------
    // Handle animation for consistency
    //----------------------------------

    consistencyAnimate(){
        $('#consistency').hover(function(){
            anime ({
                targets: '.circle-sm',
                borderRadius: ['50%', '50%'],
                scale: '1.1',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 80, 10, 0)',
                duration: 1000,
                translateX: anime.stagger(10, {grid: [3, 3], from: 'center', axis: 'x'}),
                translateY: anime.stagger(10, {grid: [3, 3], from: 'center', axis: 'y'}),
                delay: anime.stagger(25, {direction: 'default'}),
            });
        }, function (){
            anime ({
                targets: '.circle-sm',
                borderRadius: ['50%', '50%'],
                scale: '1',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 80, 10, 0)',
                duration: 1000,
                translateX: anime.stagger(0, {grid: [3, 3], from: 'center', axis: 'x'}),
                translateY: anime.stagger(0, {grid: [3, 3], from: 'center', axis: 'y'}),
                delay: anime.stagger(25, {direction: 'reverse'}),
            });
        })
    }

    //------------------------------------
    // Handle animation for collaboration
    //------------------------------------

    collaborationAnimate(){
        $('#collaboration').hover(function(){
            anime({
                targets: '.man',
                scale: '0.85',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 20, 0)',
                duration: 1000,
            })
            anime({
                targets: '.two',
                translateX: -15,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 15, 0)',
                duration: 1000,
            })
            anime({
                targets: '.four',
                translateX: 15,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 15, 0)',
                duration: 1000,
            })
            anime({
                targets: '.one, .five',
                opacity: '1',
                easing: 'easeInOutExpo',
                duration: 1000,
            })
            anime({
                targets: '.one',
                scale: '1',
                translateX: -85,
                easing: 'easeInOutExpo',
                easing: 'spring(1, 90, 15, 0)',
                duration: 1000,
            })
            anime({
                targets: '.five',
                scale: '1',
                translateX: 85,
                easing: 'easeInOutExpo',
                easing: 'spring(1, 90, 15, 0)',
                duration: 1000,
            })
        }, function(){
            anime({
                targets: '.man',
                scale: '1',
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 15, 0)',
                duration: 1000,
            })
            anime({
                targets: '.one, .five',
                opacity: '0',
                easing: 'easeInOutExpo',
                duration: 1000,
            })
            anime({
                targets: '.one',
                scale: '0.8',
                translateX: -65,
                easing: 'easeInOutExpo',
                easing: 'spring(1, 90, 15, 0)',
                duration: 1000,
            })
            anime({
                targets: '.five',
                scale: '0.8',
                translateX: 65,
                easing: 'easeInOutExpo',
                easing: 'spring(1, 90, 15, 0)',
                duration: 1000,
            })
            anime({
                targets: '.two, .four',
                translateX: 0,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 15, 0)',
                duration: 1000,
            })
        })
    }

    //--------------------------------
    // Handle animation for evolution
    //--------------------------------

    evolutionAnimate(){
        $('#evolution').hover(function(){
            anime ({
                targets: '.plant',
                easing: 'easeInOutExpo',
                scale: '1.1',
                easing: 'spring(2, 80, 10, 0)',
                translateY: -20,
                duration: 1000
            });
            anime ({
                targets: '.hand',
                easing: 'easeInOutExpo',
                scale: '1.1',
                easing: 'spring(2, 80, 10, 0)',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.plant',
                easing: 'easeInOutExpo',
                scale: '1',
                easing: 'spring(2, 80, 10, 0)',
                translateY: 0,
                duration: 1000,
            });
            anime ({
                targets: '.hand',
                easing: 'easeInOutExpo',
                scale: '1',
                easing: 'spring(2, 80, 10, 0)',
                duration: 1000
            });
        });
    }
}
