import { store } from '../../store/store.js';
/**
 * Inittab
 */
export default class InitTab{

	constructor(){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
      $tab: $('.portal-tab'),
      $tabItems: $('.portal-tab__items'),
      $tabItem: $('.portal-tab__item'),
      $tabNav: $('.portal-tab__navigation'),
      $tabNavInner: $('.portal-tab__navigation-inner'),
      $tabContent: $('.portal-tab__content'),
      sticky: 'is-sticky',
      scrolling: false,
		};

		/**
		 * Initalise Class Functions
		 */

		if (this.cache.$tab.length > 0) {
			this.handleTabLoad()
			this.handleListeners()
      this.handleKeyboardUser()
		}

	}

	handleTabLoad() {
    // Set first tab item to active
    this.cache.$tab.each(function(index){
      $(this).find(this.cache.$tabItem.first().addClass('is-active'))
    }.bind(this))

        // Append ul to nav bar
		this.cache.$tabNavInner.html('<ul></ul>');

        // Find all headings, convert text to css-safe names and append as li
		$(this.cache.$tabItems.find('[class^="portal-tab__heading"]')).each(function(index, self){
            const $_self = $(self);
			let anchorTag = $_self.text().replace(/\s+/g, '-').toLowerCase();
			$_self.find($(this.cache.$tabNavInner.find('ul')).append(`<li><a href="#${anchorTag}" data-anchor="${anchorTag}">${$_self.text()}</a></li>`));
		}.bind(this));

        // Set first nav item as active
        $(this.cache.$tabNavInner.find('ul')).each(function(index, self){
            const $_self = $(self);
            $_self.find($(this.cache.$tabNavInner.find('a')).first().addClass(store.defaultClasses.active));
        }.bind(this));
	}

	handleListeners(e) {

		//Makes sure tabs are aligned on load, scroll and resize
		$(window).on('load scroll resize', function() {
			// this.handleNavigationState();
		}.bind(this))

		$(window).on('load resize', function() {
			// this.handleResize();
		}.bind(this));

        // Handle tab switch
        $(this.cache.$tabNavInner.find('a')).click(function(e){
            e.preventDefault();

            if ($(e.currentTarget).hasClass(store.defaultClasses.active)) return;

            $(this.cache.$tabNavInner.find('a')).removeClass(store.defaultClasses.active);
            $(this.cache.$tabItem).removeClass(store.defaultClasses.active);
            $(e.currentTarget).addClass(store.defaultClasses.active);
            $(this.cache.$tabItem).hide();

            let tabToShow = $(e.currentTarget).attr('href');
            $(tabToShow).addClass(store.defaultClasses.active);
            $(tabToShow).fadeIn();
        }.bind(this));

	}

    handleNavigationState(){
        if (this.cache.$tab.is(":visible")) {
            let stickyStart = $(this.cache.$tabNav).offset().top;
            let stickyEnd = this.cache.$tabItems.position().top + this.cache.$tabItems.offset().top + this.cache.$tabItems.outerHeight(true);

            // Offset bottom of GEM Header
            let headerEnd = $('.header__portal').position().top + $('.header__portal').offset().top + $('.header__portal').outerHeight(true);
            // Offset right of sidenav
            let sideNavRight = $('.sidenav__portal').offset().left + $('.sidenav__portal').outerWidth(true);
            // Offset left of text column
            let spacingRight = ($('.portal-tab').offset().left) - sideNavRight;

            if(!this.cache.scrolling){
                this.cache.scrolling = true;

                // Checks whether to stick tabs beneath GEM header or the top of the window
                if($('.header__main').hasClass(store.defaultClasses.active) || ((window.pageYOffset + headerEnd) >= stickyStart)){
					console.log('Stick')
                    this.handleStickToHeader(headerEnd, sideNavRight, spacingRight, stickyStart);
                } else if (!$('.header__main').hasClass(store.defaultClasses.active) || (window.pageYOffset >= stickyStart)) {
                    this.handleStickToTop(headerEnd, sideNavRight, spacingRight, stickyStart);
                } else {
                    $(this.cache.$tabNav).removeClass(this.cache.sticky);
                    $(this.cache.$tabNavInner).css('top', 0);
                    $(this.cache.$tabNavInner).css('left', 0);
                    $(this.cache.$tabNavInner.find('ul')).css('margin-left', 0);
                    $(this.cache.$tabNav.addClass('portal__grid-breaker'));
                }

                setTimeout(function () {
                    this.cache.scrolling = false;
                }.bind(this), 10);
            }
        }
    }

    handleStickToHeader(headerEnd, sideNavRight, spacingRight, stickyStart){
            $(this.cache.$tabNav).addClass(this.cache.sticky);
            $(this.cache.$tabNavInner).css('top', headerEnd);
            $(this.cache.$tabNavInner).css('left', sideNavRight);
            $(this.cache.$tabNavInner.find('ul')).css('margin-left', spacingRight);
            $(this.cache.$tabNav.removeClass('portal__grid-breaker'));
    }

    handleStickToTop(headerEnd, sideNavRight, spacingRight, stickyStart){
            $(this.cache.$tabNav).addClass(this.cache.sticky);
            $(this.cache.$tabNavInner).css('top', 0);
            $(this.cache.$tabNavInner).css('left', sideNavRight);
            $(this.cache.$tabNavInner.find('ul')).css('margin-left', spacingRight);
            $(this.cache.$tabNav.removeClass('portal__grid-breaker'));
    }

	handleResize() {
        if(store.cache.$window.width() < 992) {
            $(this.cache.$tabItem).show();
        } else if (store.cache.$window.width() > 992) {
            $(this.cache.$tabItem)
        }
	}

    /**
     * @property {Function} handleKeyboardUser  - Handles tab spacebar keyboard stroke - open/close
     * @returns void
     */
    handleKeyboardUser(){
        this.cache.$tabNavInner.find('a').keydown(function(e){
            if (e.keyCode == 32) {
                e.preventDefault();
                this.handleListeners($(e.target));
            }
        }.bind(this));
    }
}
