/**
 * InitFormCounter
 */
export default class InitFormCounter{

	constructor(parentSelector=''){

		/**
		 * Initalise Class Cache
		 * @type {obj}
		 */
		this.cache = {
			$formCounter:	$(`${parentSelector} .form__counter`)
		};

		/**
		 * Initalise Class Functions
		//  */
		this.addCounterComponent();
	}


	/**
	 * @property {Function} addCounterComponent  - Adds character counter to input or textarea element
	 * @returns void
	 */
	addCounterComponent() {
		this.cache.$formCounter.each(function(index, self){
			const $_self 	= $(self);
			const $input 	= $_self.find("input, textarea");
			const maxLength = Number($input.attr("maxlength"));

			if(maxLength){
				$_self.append(
					`<div class="form__input-counter">
						<span class="form__input-counter__current"></span> / <span class="form__input-counter__max"></span>
					</div>`
				);

				const $currentCount = $_self.find('.form__input-counter__current');
				const $maxCount 	= $_self.find('.form__input-counter__max');

				$currentCount.text( $input.val().length );
				$maxCount.text( maxLength );

				this.handleInputChange($input, $currentCount, $maxCount, maxLength);
			}
		}.bind(this));
	}

	/**
	* @property {Function} handleInputChange  - Counts characters typed
	* @returns void
	*/
	handleInputChange($input, $currentCount, $maxCount, maxLength){
		$input.on('input', function(e){
			const currentVal = $(this).val().length;
			$currentCount.text(currentVal);
			if ( currentVal === maxLength ) e.preventDefault();
		});
	}


}
