import '../../lib/rangeslider.min.js';
import { store } from '../../store/store.js';

/**
  * InitSlider
  */
export default class InitSlider {

	constructor(parentSelector='') {

		/**
 		 * Initalise Class Cache
 		 * @type {obj}
 		 */
		this.cache = {
			$slider: 		$(`${parentSelector} .slider`),
			$input:			$(`${parentSelector} .slider__input`),
			sliderClass:	'.slider',
			inputClass: 	'.slider__input',
			wrapClass: 		'.slider__wrap',
			valueClass: 	'.slider__value',
			tooltipClass:	'.slider__tooltip',
			thumbClass:		'.slider__thumb',
		};

		/**
 		 * Initalise Class Functions
 		 */
		if(!this.cache.$input.hasClass(store.defaultClasses.initialise)) {
			this.renderSlider();
			this.handleSliderrightClick();
			this.handleInputBlur();
		}
	}

	/**
 	 * @property {Function} renderSlider -
 	 * @returns void
 	 */
	renderSlider() {
		this.cache.$slider.each(function(index, self){
			const $_self = $(self);
			const cache = {
				$this: 	$_self,
				$input: $_self.find(this.cache.inputClass),
				$wrap: 	$_self.find(this.cache.wrapClass),
			};
			this.renderSliderValueBox(cache);
			this.renderMinMax(cache);
			this.initRangeSlider(cache);
			this.renderStepDots(cache);
		}.bind(this));
	}


	/**
 	 * @property {Function} initRangeSlider -
 	 * @returns void
 	 */
	initRangeSlider(cache){
		cache.$input.rangeslider({
			polyfill: 			false,
			rangeClass: 		'slider__track',
			horizontalClass: 	'',
			verticalClass: 		'',
			activeClass: 		store.defaultClasses.active,
			fillClass: 			'slider__track-fill',
			handleClass: 		'slider__thumb',
			disabledClass:		store.defaultClasses.disabled,
			onInit: 			() => this.renderSliderTooltip(cache),
			onSlide: 			(position, value) => this.handleOnSlide(cache, value),
			onSlideEnd:			() => this.handleSlideEnd(cache),
		});
	}


	/**
 	 * @property {Function} renderSliderValueBox -
 	 * @returns void
 	 */
	renderSliderValueBox(cache){
		if(cache.$input.data('current-value')){
			const width = 30 + (cache.$input.attr("max").length * 10);
			cache.$this.addClass("slider--fancy");
			cache.$wrap.css("width", `calc(100% - ${ width + 30 }px)`);
			cache.$this.find(this.cache.wrapClass).append(
				`<span class='slider__value' style="width: ${width}px; right: -${width + 30}px">${ cache.$input.val() }</span>`
			);
		}
	}

	/**
 	 * @property {Function} renderExtremeValues -
 	 * @returns void
 	 */
	renderMinMax(cache){
		if(cache.$input.data('min-max')){
			cache.$this.addClass("slider--fancy");
			cache.$this.find(this.cache.wrapClass)
			.append(`<span class='slider__min' aria-label='Minimum slide value is ${ cache.$input.attr("min") }'>${ cache.$input.attr("min") }</span>`)
			.append(`<span class='slider__max' aria-label='Maximum slide value is ${ cache.$input.attr("max") }'>${ cache.$input.attr("max") }</span>`);
		}
	}


	/**
 	 * @property {Function} renderSliderTooltip -
 	 * @returns void
 	 */
	renderSliderTooltip(cache){
		if(cache.$input.data('tooltip')){
			cache.$this.find(this.cache.thumbClass).append(`<span class='slider__tooltip'>${ cache.$input.val() }</span`);
		}
	}


	/**
 	 * @property {Function} renderStepDots -
 	 * @returns void
 	 */
	renderStepDots(cache){
		const step = Number(cache.$input.attr('step'));
		const max = Number(cache.$input.attr('max'));

		const renderDot = function(){
			let dots = "";
			for(let i = 0; i < max / step + 1; i++){
				dots += "<span></span>";
			}
			return dots;
		};

		if(step > 1 && max % step === 0){
			cache.$this.find(this.cache.wrapClass).append(
				`<div class='slider__track-step' aria-hidden='true'>${ renderDot() }</div>`
			);
		}
	}


	/**
 	 * @property {Function} handleOnSlide -
 	 * @returns void
 	 */
	handleOnSlide(cache, value){
		cache.$this.find(this.cache.valueClass).text(value);
		cache.$this.find(this.cache.tooltipClass).text(value);
		cache.$this.addClass(store.defaultClasses.active);
	}


	/**
 	 * @property {Function} handleSlideEnd -
 	 * @returns void
 	 */
	handleSlideEnd(cache){
		cache.$this.removeClass(store.defaultClasses.active).addClass(store.defaultClasses.success);
	}


	/**
 	 * @property {Function} handleSliderrightClick -
 	 * @returns void
 	 */
	handleSliderrightClick(){
		this.cache.$slider.contextmenu(function(e){
			e.preventDefault();
		});
	}

	/**
 	 * @property {Function} handleInputBlur -
 	 * @returns void
 	 */
	handleInputBlur(){
		this.cache.$input.blur(function(e){
			$(e.currentTarget).closest(this.cache.sliderClass).removeClass(store.defaultClasses.active).addClass(store.defaultClasses.success);
		}.bind(this));
	}
}
