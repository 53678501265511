import { store } from '../../store/store.js';
import anime from 'animejs/lib/anime.es.js';

export default class IconographyAnime{

    //----------------------
    // Initialise functions
    //----------------------

	constructor(){
        this.onscrollAnimate();

	}

    //------------------------------------
    // Handle animation for functionality
    //------------------------------------

    onscrollAnimate(){
        $(document).ready(function(){
            anime({
                targets: '.portal-banner',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 1500
            })
            anime({
                targets: '.breadcrumb',
                opacity: [0,1],
                easing: 'easeInOutExpo',
                duration: 1500
            })
        })
    }
}
