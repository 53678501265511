import { store } from '../../store/store.js'
//#TODO remove hardcoded classes and replace with store variables

import intlTelInput from 'intl-tel-input'
import { EXP, EDQ } from '../../lib/experian.js'

export default class InitFormValidation {
  constructor(form) {
    this.form = form
    this.numbersTested = []
    this.emailsTested = []
    this.phoneFields = {}
    this.setUpEventListeners()
    //TODO put conditional here to check if contains input[type=tel]
    this.setUpPhoneValidation()
  }

  setUpEventListeners() {
    // console.log(this.form)
    let _this = this

    this.form.on('change', 'select, input[type=radio], input[type=checkbox]', function () {
      console.log('Validation triggered: ', $(this).attr('id'))
      _this.validate($(this), function (isvalid) {})
    })

    this.form.on(
      'focusout',
      'input[type=text], input[type=tel], input[type=phone], input[type=email], textarea',
      function () {
        console.log('Validation triggered: ', $(this).attr('id'))
        _this.validate($(this), function (isvalid) {})
      }
    )

    this.form.on('click', '.dropdown__list-item', function () {
      console.log('Validation triggered: ', $(this).closest('fieldset').find('select').attr('id'))
      _this.validate($(this).closest('fieldset').find('select'), function (isvalid) {})
    })

    this.form.find('input, select, textarea').on('invalid', function (e) {
      console.log('Error detected on: ' + e.target.id)
      console.log('Validation triggered: ', $(this).attr('id'))
      _this.validate($(this), function (isvalid) {})
      e.preventDefault()
    })
  }

  isValid(callback) {
    // Loop through and check validity of each element
    let valid = true

    // Removed select from find()
    // Removed input.dropdown__button from not()
    let formFields = this.form
      .find('input, textarea')
      .not('input[type="hidden"], input[type="range"], input[type="submit"], .dropdown__checkbox')

    let totalFormFields = formFields.length - 1
    formFields.each(
      function (index, _this) {
        console.log('Field index:', index + '/' + totalFormFields, 'Field: ', _this)

        this.validate($(_this), function (itemValid) {
          if (!itemValid) {
            console.log('Field index:', index, 'FAILED', itemValid)
            valid = false
          }

          //   console.log(index, totalFormFields)
          if (index === totalFormFields) {
            console.log('Finished checking fields, returning callback...')
            return callback(valid)
          }
        })
      }.bind(this)
    )

    //		if(valid)
    //			return true
    //		else
    //			return false
  }

  validate(_this, callback) {
    if (_this.is('[type=submit]')) {
      return
    }

    this.customFieldValidation(
      _this,
      function (isValid) {
        //first check the custom validation

        //then check normal fields
        let fieldValid = document.getElementById(_this.attr('id')).validity.valid

        console.log('Field validity:', fieldValid)

        if (!fieldValid) {
          this.makeInvalid(_this)
          return callback(fieldValid)
        }

        if (_this.val().length) {
          this.makeValid(_this)
        } else {
          this.makeNeutral(_this)
        }

        return callback(fieldValid)
      }.bind(this)
    )
  }

  customFieldValidation(_this, callback) {
    console.log('Field type:', _this.prop('type'), 'Field required:', _this.prop('required'))

    if (typeof _this.prop('type') === 'undefined') {
      console.log('Undefined field type...')
      return callback(false)
    }

    if (
      _this.prop('type') != 'email' &&
      _this.prop('type') != 'tel' &&
      _this.prop('type') != 'checkbox'
    )
      return callback(false)

    console.log('Running custom validation...')

    //email
    if (_this.is('[type=email]')) {
      this.checkEmail(_this, function (isValid) {
        return callback(isValid)
      })
    }

    //phone
    if (_this.is('[type=tel]')) {
      this.checkPhone(_this, function (isValid) {
        return callback(isValid)
      })
    }

    //checkbox
    if (_this.is('[type=checkbox]')) {
      this.checkBox(_this, function (isValid) {
        return callback(isValid)
      })
    }
  }

  checkBox(_this, callback) {
    //see if it has required class
    if (!$(_this).closest('fieldset').hasClass('required')) return callback(true)

    if ($(_this).closest('fieldset').find('input[type=checkbox]:checked').length > 0) {
      //   console.log('set valid')
      $(_this)
        .closest('fieldset')
        .find('input[type=checkbox]')
        .each(function () {
          console.log('Setting', $(this).attr('id'), 'valid...')
          document.getElementById($(this).attr('id')).setCustomValidity('')
        })
    } else {
      document.getElementById(_this.attr('id')).setCustomValidity('Please check this field.')
    }

    return callback(true)
  }

  checkEmail(_this, callback) {
    let email = _this.val()

    if (email.length === 0) return callback(false)

    //if we have already tested exptest, no need to retest
    if (this.emailsTested.indexOf(email) > -1) {
      //   console.log('aready tested', this.emailsTested.indexOf(email), this.emailsTested)
      document.getElementById(_this.attr('id')).setCustomValidity('')
      return callback(false)
    }

    this.showInputValidating(_this)

    console.log('Email sent to Experian: ' + email)
    this.checkEmailExperian(
      email,
      function (err, result) {
        this.hideInputValidating(_this)

        if (err) {
          document.getElementById(_this.attr('id')).setCustomValidity('')
          return callback(false)
        }

        if (result.certainty === 'unknown' || result.certainty === 'verified') {
          console.log('Email verified with experian with code: ' + result.certainty)
          this.emailsTested.push(email)
          document.getElementById(_this.attr('id')).setCustomValidity('')

          return callback(false)
        } else {
          console.log('Email failed with experian with code ' + result.certainty)

          if (result.suggestions) console.log('Suggestions: ', result.suggestions)
          //                    return callback(result.suggestions);

          document.getElementById(_this.attr('id')).setCustomValidity('Please enter a valid email.')
          return callback(true)
        }
      }.bind(this)
    )
  }

  checkEmailExperian(email, callback) {
    var validateEmail = new EXP.DQ.Email.Client({ token: EDQ.sample.token }).validate(email)

    validateEmail.done(function (done) {
      callback(null, done)
    })

    validateEmail.fail(function (error) {
      callback(error)
    })
  }

  checkMobileExperian(number, callback) {
    var validateMobile = new EXP.DQ.Mobile.Client({ token: EDQ.sample.token }).validate(number)

    validateMobile.done(function (done) {
      callback(null, done)
    })

    validateMobile.fail(function (error) {
      callback(error)
    })
  }

  checkPhone(_this, callback) {
    if (_this.attr('type') != 'tel') return callback(false)

    if (_this.val().length === 0) return callback(false)

    if (!(_this.attr('id') in this.phoneFields)) return callback(false)

    //first validate with international phone numbers plugin
    let iti = this.phoneFields[_this.attr('id')]
    if (!iti.isValidNumber()) {
      let error = iti.getValidationError()
      let validationMessage = ''

      switch (error) {
        case 0:
          validationMessage = 'Enter a valid mobile number.'
          break
        case 1:
          validationMessage = 'Invalid country code'
          break
        case 2:
          validationMessage = 'The number entered is too short'
          break
        case 3:
          validationMessage = 'The number entered is too long'
          break
        case 4:
          validationMessage = 'Enter a valid mobile number'
          break
      }

      document.getElementById(_this.attr('id')).setCustomValidity(validationMessage)
      return callback(true)
      //return custom error
    }

    //it's passed, now check experian (only check if it's an australian number)
    let selectedCountry = iti.getSelectedCountryData()
    if (selectedCountry.iso2 != 'au') {
      return callback(false)
    }

    //it's valid and australian now lets check with experian

    let number = iti.getNumber()

    //empty number allowed
    if (!number) return callback(false)

    //we have already tested exptest, no need to retest
    if (this.numbersTested.indexOf(number) > -1) {
      document.getElementById(_this.attr('id')).setCustomValidity('')
      return callback(false)
    }

    this.showInputValidating(_this)
    console.log('Number sent to Experian: ' + number)

    this.checkMobileExperian(
      number,
      function (err, result) {
        this.hideInputValidating(_this)

        if (err) {
          console.log('Experian mobile error:', err)
          document.getElementById(_this.attr('id')).setCustomValidity('')
          return callback(false)
        }

        if (parseInt(result.resultCode) === 3 || parseInt(result.resultCode) === 2) {
          //codes: 2 = absent, 3 = verified.
          //                self.mobileValidation.exptest = true;
          console.log('Number verified with experian with code: ' + result.resultCode)
          this.numbersTested.push(number)
          document.getElementById(_this.attr('id')).setCustomValidity('')
          return callback(false)
        } else {
          //                self.mobileValidation.exptest = false;
          console.log('Failed with experian, code ' + result.resultCode)

          document
            .getElementById(_this.attr('id'))
            .setCustomValidity('Please enter a valid mobile number.')
          return callback(true)
        }
      }.bind(this)
    )
  }

  setUpPhoneValidation() {
    $('head').append(
      "<link href='https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.2/css/intlTelInput.css' type='text/css' rel='stylesheet' />"
    )

    //		const config = {
    //			initialCountry: "au",
    //			preferredCountries: ["au"],
    //			nationalMode: true,
    //			utilsScript : "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.2/js/utils.js"
    //		}
    //
    //		const phoneFields = document.querySelectorAll(`#${this.form.attr('id')} input[type=tel]`);
    //		console.log('set up phone validation', `#${this.form.attr('id')} input[type=tel]`)
    //		phoneFields.forEach((input) => {
    //			this.phoneFields[input.id] = intlTelInput(input, config)
    //			//move the label
    //			$('#'+input.id).closest('fieldset').find('label').detach().appendTo($('#'+input.id).closest('fieldset').find('.iti'));
    //		});
  }

  setUpPhoneField(_this) {
    const config = {
      initialCountry: 'au',
      preferredCountries: ['au'],
      nationalMode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.2/js/utils.js',
    }

    const phoneFields = document.querySelectorAll(`#${_this.attr('id')}`)
    console.log('Setting up phone validation...', `#${_this.attr('id')}`)
    phoneFields.forEach(input => {
      //   console.log('found input for phone validation')
      this.phoneFields[input.id] = intlTelInput(input, config)
      //move the label
      $('#' + input.id)
        .closest('fieldset')
        .find('label')
        .detach()
        .appendTo(
          $('#' + input.id)
            .closest('fieldset')
            .find('.iti')
        )
    })
  }

  makeValid(_this) {
    let selectField = _this.closest('fieldset').find('.dropdown__button')

    if (selectField.length > 0) selectField.addClass('is-success').removeClass('is-error')
    else if (_this.attr('type') === 'checkbox' || _this.attr('type') === 'radio')
      _this.closest('fieldset').find('input').addClass('is-success').removeClass('is-error')
    else _this.addClass('is-success').removeClass('is-error')

    this.hideError(_this)

    console.log('Make valid...')
  }

  makeNeutral(_this) {
    let selectField = _this.closest('fieldset').find('.dropdown__button')

    if (selectField.length > 0) selectField.removeClass('is-success').removeClass('is-error')
    else if (_this.attr('type') === 'checkbox' || _this.attr('type') === 'radio')
      _this.closest('fieldset').find('input').removeClass('is-error')
    else _this.removeClass('is-success').removeClass('is-error')

    this.hideError(_this)
    console.log('Make neutral...')
  }

  makeInvalid(_this) {
    if (!$(_this).prop('required')) return
    let selectField = _this.closest('fieldset').find('.dropdown__button')
    console.log('Invalid input detected...', selectField.length, _this.attr('id'))
    if (selectField.length > 0) selectField.addClass('is-error')
    else if (_this.attr('type') === 'checkbox' || _this.attr('type') === 'radio')
      _this.closest('fieldset').find('input').removeClass('is-success').addClass('is-error')
    else _this.addClass('is-error').removeClass('is-success')
    let msg = document.getElementById(_this.attr('id')).validationMessage
    this.showError(_this, msg)
  }

  showError(_this, msg) {
    let selectField = _this.closest('fieldset').find('.dropdown__button')
    if (selectField.length > 0) {
      //   console.log('append', _this, selectField)
      selectField.parent().find('.form__field-error').remove()
      selectField.parent().append(`<span class="form__field-error">${msg}</span>`)
    } else if (_this.attr('type') === 'checkbox' || _this.attr('type') === 'radio') {
      _this.closest('fieldset').find('.form__field-error, .ico').remove()
      _this.closest('fieldset').append(`<span class="form__field-error">${msg}</span>`)
    } else {
      _this.parent().find('.form__field-error, .ico').remove()
      _this
        .parent()
        .append(
          `<span class="form__field-error">${msg}</span><i class="ico ico--f-blocked ico--sm"></i>`
        )
    }
    console.log('Validation error: ', msg)
  }

  hideError(_this) {
    let selectField = _this.closest('fieldset').find('.dropdown__button')

    if (selectField.length > 0) {
      //   console.log('HIDE SELECT ERROR', _this)
      selectField.parent().find('.form__field-error').remove()
    } else if (_this.attr('type') === 'checkbox' || _this.attr('type') === 'radio') {
      _this.closest('fieldset').find('.form__field-error').remove()
    } else {
      _this.parent().find('.form__field-error, .ico').remove()
      _this.parent().append(`<i class='ico ico--f-tick ico--sm'></i>`)
    }
    // console.log('Hide error...')
  }

  showInputValidating(_this) {
    _this.closest('fieldset').find('.form__field-error, .ico').remove()
    _this.parent().append(`<i class='icon--loading'></i>`)
    _this.prop('disabled', true)
    this.form.find('input[type=submit], button[type=submit]').prop('disabled', true)
  }

  hideInputValidating(_this) {
    console.log('Hide input validating...')
    _this.prop('disabled', false)
    _this.parent().find('.icon--loading').remove()
    this.form.find('input[type=submit], button[type=submit]').prop('disabled', false)
  }
}
