import { store } from '../../store/store.js';
import anime from 'animejs/lib/anime.es.js';

export default class GridAnime{

    //----------------------
    // Initialise functions
    //----------------------

	constructor(){
        this.minimumAnimate();
        this.smallAnimate();
        this.mediumAnimate();
        this.largeAnimate();
        this.maximumAnimate();
	}

    //------------------------------------
    // Handle animation for functionality
    //------------------------------------

    minimumAnimate(){
        $('#grid-minimum').hover(function(){
            anime ({
                targets: '.grid-minimum__detail',
                opacity: [0,1],
                translateX: [-15, 0],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-minimum',
                scale: 1.015,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.grid-minimum__detail',
                opacity: [1,0],
                translateX: [0, -15],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-minimum',
                scale: 1,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        })
    }

    smallAnimate(){
        $('#grid-small').hover(function(){
            anime ({
                targets: '.grid-small__detail',
                opacity: [0,1],
                translateX: [-15, 0],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-small',
                scale: 1.015,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.grid-small__detail',
                opacity: [1,0],
                translateX: [0, -15],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-small',
                scale: 1,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        })
    }

    mediumAnimate(){
        $('#grid-medium').hover(function(){
            anime ({
                targets: '.grid-medium__detail',
                opacity: [0,1],
                translateX: [-15, 0],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-medium',
                scale: 1.015,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.grid-medium__detail',
                opacity: [1,0],
                translateX: [0, -15],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-medium',
                scale: 1,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        })
    }

    largeAnimate(){
        $('#grid-large').hover(function(){
            anime ({
                targets: '.grid-large__detail',
                opacity: [0,1],
                translateX: [-15, 0],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-large',
                scale: 1.015,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.grid-large__detail',
                opacity: [1,0],
                translateX: [0, -15],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-large',
                scale: 1,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        })
    }

    maximumAnimate(){
        $('#grid-maximum').hover(function(){
            anime ({
                targets: '.grid-maximum__detail',
                opacity: [0,1],
                translateX: [-15, 0],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-maximum',
                scale: 1.015,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.grid-maximum__detail',
                opacity: [1,0],
                translateX: [0, -15],
                easing: 'easeInOutExpo',
                delay: anime.stagger(50),
                duration: 1000
            });
            anime({
                targets: '.grid-maximum',
                scale: 1,
                easing: 'easeInOutExpo',
                duration: 1000
            });
        })
    }
}
