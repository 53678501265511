import { store } from '../../store/store.js'

/**
 * InitFormDropdown
 */
export default class InitFormDropdown {
  constructor(parentSelector = '') {
    /**
     * Initalise Class Cache
     * @type {obj}
     */
    this.cache = {
      $select: $(`${parentSelector}.form__input select`).not(`.${store.defaultClasses.initialise}`),
      $dropdown: $(`${parentSelector}.dropdown`),
      wrapClass: '.dropdown__wrap',
      listClass: '.dropdown__list',
      selectClass: '.dropdown__select',
      optgroupClass: '.dropdown__select optgroup',
      optionClass: '.dropdown__select option',
      labelClass: '.dropdown__label',
      buttonClass: '.dropdown__button',
      listItemClass: '.dropdown__list-item',
    }

    // console.log(this.cache.$select.hasClass(store.defaultClasses.initialise), 'initialised')
    if (!this.cache.$select.hasClass(store.defaultClasses.initialise)) {
      this.handleNativeSelectLabel()
      this.renderDropdown()
      this.handleOnClick()
      this.handleOnChange()
      this.handleOnFocus()
      this.handleKeypress()
      this.HandleMouseHover()
    }
  }

  /**
   * @property {Function} handleNativeSelectLabel  - Description Here
   * @returns void
   */
  handleNativeSelectLabel() {
    this.cache.$select.each(function () {
      if (!$(this).hasClass('dropdown__select')) {
        if ($(this).find(':selected') && !$(this).find(':selected').prop('disabled')) {
          $(this).addClass(store.defaultClasses.active)
        }
      }
    })

    this.cache.$select.on('change', function () {
      $(this).addClass(store.defaultClasses.active)
    })
  }

  /**
   * @property {Function} renderDropdown  - Description Here
   * @returns void
   */
  renderDropdown() {
    this.cache.$dropdown.each(
      function (index, self) {
        if ($(self).find(`.${store.defaultClasses.initialise}`).length > 0) return
        const $_self = $(self)

        $_self.append("<div class='dropdown__wrap'><ul class='dropdown__list'></ul></div>")
        const $dropdownWrap = $_self.find(this.cache.wrapClass),
          $dropdownList = $_self.find(this.cache.listClass),
          $dropdownSelect = $_self.find(this.cache.selectClass),
          $dropdownLabel = $_self.find(this.cache.labelClass),
          $dropdownSelected = $_self.find(':selected'),
          isButton = $_self.hasClass('dropdown--button')
        let placeholder = '',
          value = '',
          $hidden = $_self.find("input[type='hidden']")

        $dropdownSelect.addClass(store.defaultClasses.hidden)

        if ($_self.hasClass('js-required-validation')) {
          $dropdownWrap.addClass('js-validation').addClass('js-required-validation')
          $_self.removeClass('js-validation').removeClass('js-required-validation')
        }

        if ($hidden.length > 0) {
          $hidden = $hidden.val().length > 0 ? $hidden.val().split(',') : ''
        }

        if ($dropdownSelect.data('type') !== 'checkbox' || !$dropdownSelect.prop('multiple')) {
          if ($dropdownSelected.prop('disabled')) {
            placeholder = $dropdownSelected.text()
          } else {
            value = $dropdownSelected.text()
          }
        }

        if ($dropdownSelect.data('type') === 'checkbox' || $dropdownSelect.prop('multiple')) {
          if ($dropdownSelected.prop('disabled') && $hidden.length === 0) {
            placeholder = $dropdownSelected.text()
          } else {
            let values = []
            $.each($hidden, function () {
              values.push($_self.find(`option[value="${this}"]`).text())
            })
            value = values.join(', ')
          }
        }

        const renderDropListItem = $option => {
          if ($dropdownSelect.data('type') === 'checkbox' || $dropdownSelect.prop('multiple')) {
            return `<li class="dropdown__list-item form__checkbox" data-value="${$option.val()}">${$option.text()}<input aria-label="${$option.text()}" class="dropdown__checkbox" type="checkbox" tabindex="-1" ${
              Array.isArray($hidden) && $hidden.includes($option.val()) ? 'checked' : ''
            }></li>`
          } else if ($dropdownSelect.data('type') === 'radio') {
            return `<li class="dropdown__list-item form__radio" data-value="${$option.val()}">${$option.text()}<input aria-label="${$option.text()}" type="radio" tabindex="-1" ${
              $option.is(':selected') ? 'checked' : ''
            }></li>`
          } else {
            return `<li class="dropdown__list-item" data-value="${$option.val()}">${$option.text()}</li>`
          }

          //   switch ($dropdownSelect.data('type')) {
          //     case 'checkbox':
          //       return `<li class="dropdown__list-item form__checkbox" data-value="${$option.val()}">${$option.text()}<input aria-label="${$option.text()}" type="checkbox" tabindex="-1" ${
          //         Array.isArray($hidden) && $hidden.includes($option.val()) ? 'checked' : ''
          //       }></li>`
          //     case 'radio':
          //       return `<li class="dropdown__list-item form__radio" data-value="${$option.val()}">${$option.text()}<input aria-label="${$option.text()}" type="radio" tabindex="-1" ${
          //         $option.is(':selected') ? 'checked' : ''
          //       }></li>`
          //     default:
          // 		return `<li class="dropdown__list-item" data-value="${$option.val()}">${$option.text()}</li>`
          //   }
        }

        const renderInput = () => {
          return `<input type="text" class="dropdown__button" placeholder="${placeholder}" value="${value}" id="${$dropdownSelect.attr(
            'id'
          )}-input" readonly aria-readonly="true" aria-label="${placeholder}"/>`
        }

        const renderLabel = () => {
          return $dropdownLabel.length > 0
            ? `<label for="${$dropdownSelect.attr('id')}-input">${$dropdownLabel.text()}</label>`
            : ''
        }

        const renderIco = () => {
          const icon = isButton ? 'caret-d' : 'chevron-d-s'
          return `<i class="ico ico--f-${icon} ico--sm" aria-hidden="true"></i>`
        }

        $dropdownWrap.prepend(renderInput() + renderLabel() + renderIco())

        if ($_self.find(this.cache.optgroupClass).length > 0) {
          $_self.find(this.cache.optgroupClass).each(function (groupIndex, self) {
            const $_self = $(self)
            $dropdownList.append(`<li class="dropdown__list-heading">${$_self.attr('label')}</li>`)

            $_self.find('option').each(function (index, self) {
              $dropdownList.append(renderDropListItem($(self)))
            })
          })
        } else {
          $_self.find(this.cache.optionClass).each(function (index, self) {
            const $_self = $(self)
            if (!$_self.data('type') && $_self.data('type') !== 'placeholder') {
              $dropdownList.append(renderDropListItem($_self))
            }
          })
        }

        $(self).find('select').addClass(store.defaultClasses.initialise)
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleOnClick  - Description Here
   * @returns void
   */
  handleOnClick() {
    store.cache.$document.on(
      'click',
      function (e) {
        if (!$(e.target).is('.dropdown, .dropdown *') || $(e.target).is('.ico')) {
          this.handleState(null, 'hidden')
        }
      }.bind(this)
    )

    this.cache.$dropdown.on(
      'click',
      'input',
      function (e) {
        const $_self = $(e.currentTarget)
        this.handleState($_self, 'hidden')
        this.handleState($_self, 'active')
        e.preventDefault()
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleState  - Description Here
   * @returns void
   */
  handleState($_self, state) {
    if (state === 'active') {
      const $parent = $_self.parent()
      $parent.find(this.cache.listClass).addClass(store.defaultClasses.active)
      $parent.addClass(store.defaultClasses.active)
      $parent.parent().addClass(store.defaultClasses.active)
    } else {
      this.cache.$dropdown.removeClass(store.defaultClasses.active)
      this.cache.$dropdown.find(this.cache.wrapClass).removeClass(store.defaultClasses.active)
      $(this.cache.listClass).removeClass(store.defaultClasses.active)
    }
  }

  /**
   * @property {Function} handleOnChange  - Description Here
   * @returns void
   */
  handleOnChange() {
    this.cache.$dropdown.on(
      'click',
      '.dropdown__list-item[data-value]',
      function (e) {
        const $_self = $(e.currentTarget),
          $dropdown = $_self.closest('.dropdown'),
          $select = $dropdown.find(this.cache.selectClass),
          $input = $dropdown.find('input[type=text]'),
          $hidden = $dropdown.find("input[type='hidden']"),
          $checkbox = $_self.children('input[type=checkbox]'),
          $radio = $_self.children('input[type=radio]')

        if (!$checkbox.length > 0) {
          $select.val($_self.data('value'))
          console.log($select.val())
          $input.trigger('change')
        }

        if ($checkbox.length > 0) {
          const array = [],
            data = []
          $checkbox.prop('checked')
            ? $checkbox.prop('checked', false)
            : $checkbox.prop('checked', true)
          $_self
            .parent()
            .find('input[type=checkbox]')
            .each(function () {
              if ($(this).prop('checked')) {
                array.push($(this).parent().text())
                data.push($(this).parent().data('value'))
              }
            })
          $input.val(array.join(', '))
          $hidden.val(data.join(', '))
        } else if ($radio.length > 0) {
          $_self
            .siblings()
            .find('input[type=radio]')
            .each(function () {
              $(this).prop('checked', false)
            })
          $radio.prop('checked') ? $radio.prop('checked', false) : $radio.prop('checked', true)
          $input.val($_self.text())
        } else {
          $input.val($_self.text())
          this.handleState($_self, 'hidden')
        }
        e.preventDefault()
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleOnFocus  - Description Here
   * @returns void
   */
  handleOnFocus() {
    this.cache.$dropdown.on(
      'focus',
      'input',
      function (e) {
        this.handleState($(e.currentTarget), 'active')
      }.bind(this)
    )
  }

  /**
   * @property {Function} handleKeypress  - Description Here
   * @returns void
   */
  handleKeypress() {
    const changeListItem = ($dropDownItems, operator) => {
      $dropDownItems.each(function (index) {
        const $this = $(this)
        if ($this.hasClass(store.defaultClasses.active)) {
          const nextIndex = operator === 'plus' ? index + 1 : index - 1
          if ($($dropDownItems[nextIndex]).length > 0) {
            $this.removeClass(store.defaultClasses.active)
            $($dropDownItems[nextIndex]).addClass(store.defaultClasses.active)
          }
          return false
        }
      })
    }

    this.cache.$dropdown.on(
      'keydown',
      '.dropdown__button',
      function (e) {
        const $dropDownList = $(e.currentTarget).siblings('.dropdown__list')
        const $dropDownItems = $dropDownList.find('[data-value]')
        const $activeOption = $dropDownList.find('.dropdown__list-item.is-active')

        e.preventDefault()

        if (e.keyCode === 40 || e.keyCode === 38) {
          if ($activeOption.length === 0) {
            $dropDownItems.first().addClass(store.defaultClasses.active)
          } else {
            if (e.keyCode === 40) {
              changeListItem($dropDownItems, 'plus')
            }
            if (e.keyCode == 38) {
              changeListItem($dropDownItems, 'minus')
            }
          }
        }

        if (e.keyCode === 27 || e.keyCode === 9) {
          this.handleState($(e.currentTarget), 'hidden')
        }

        if (e.keyCode === 13 || e.keyCode === 32) {
          $activeOption.click()
        }
      }.bind(this)
    )
  }

  /**
   * @property {Function} HandleMouseHover  - Description Here
   * @returns void
   */
  HandleMouseHover() {
    this.cache.$dropdown.on('mouseenter', this.cache.listItemClass, function () {
      $(this).addClass(store.defaultClasses.hover)
    })

    this.cache.$dropdown.on('mouseleave', this.cache.listItemClass, function () {
      $(this).removeClass(store.defaultClasses.hover)
    })
  }
}
