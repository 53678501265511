import { store } from '../store/store.js'
const bodyScrollLock = require('body-scroll-lock')
import * as PhotoSwipe from '../lib/photoswipe-edited.js'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

export default class InitGallery {
  constructor() {
    this.disableBodyScroll = bodyScrollLock.disableBodyScroll
    this.enableBodyScroll = bodyScrollLock.enableBodyScroll
    this.galleryConstructor = false
    this.templateHeroMax = 5
    this.templateDefaultMax = 6

    this.loadHandlebars()
    this.loadPhotoSwipe()
    this.init()
  }

  loadHandlebars() {
    var handlebars = document.createElement('script')
    handlebars.setAttribute('type', 'text/javascript')
    handlebars.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/npm/handlebars@latest/dist/handlebars.js'
    )
    $('body').append(handlebars)
  }

  loadPhotoSwipe() {
    if ($('html [href*="photoswipe.min.css"]').length < 1)
      $(
        '<link href="https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.3/photoswipe.min.css" rel="stylesheet">'
      ).appendTo('head')

    if ($('html [href*="default-skin.min.css"]').length < 1)
      $(
        '<link href="https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.3/default-skin/default-skin.min.css" rel="stylesheet">'
      ).appendTo('head')
  }

  init() {
    let _this = this

    var handlebarsInverval = setInterval(checkHandlebars, 500)
    function checkHandlebars() {
      console.log('Loading gallery...')
      if (window.Handlebars == undefined) return
      return removeHandlebarsInterval()
    }

    function removeHandlebarsInterval() {
      clearInterval(handlebarsInverval)
      initGallery()
    }

    function initGallery() {
      console.log('Gallery loaded')
      $('.my-gallery').each(function () {
        let variable = $(this).data('variable')
        _this.createPhotoSwipeHTML(variable)
        _this.initPhotoSwipe(variable)
      })
      $(document).ready(function () {
        $('.my-gallery').each(function () {
          $(this).find('.loader__skeleton--gallery').fadeOut()
        })
      })
      _this.setListeners()
      _this.handleIconSize()
    }
  }

  initPhotoSwipe(variable) {
    var openPhotoSwipe = function () {
      var pswpElement = document.querySelectorAll('.pswp')[0]
      var items = gallery[variable].map(function (item) {
        return {
          src: item.asset_url,
          w: 600,
          h: 600,
          alt: item.description,
          title: `<div itemprop="caption description">
                  <p class="gallery__caption">${item.description}</p>
                  <p class="gallery__caption--credit">${item.credit}</p>
                  </div>`,
        }
      })
      var options = {
        timeToIdleOutside: 10000,
        history: false,
        focus: true,
        closeOnVerticalDrag: true,
        closeOnScroll: false,
        showAnimationDuration: 0,
        clickToCloseNonZoomable: false,
        hideAnimationDuration: 0,
        indexIndicatorSep: ' of ',
      }

      this.galleryConstructor = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options)
      this.galleryConstructor.init()

      this.galleryConstructor.listen(
        'close',
        function () {
          this.enableBodyScroll(this.galleryConstructor)
        }.bind(this)
      )

      this.galleryConstructor.listen(
        'imageLoadComplete',
        function (index, item) {
          // index - index of a slide that was loaded
          // item - slide object
          // this.handleCaption()
          this.handleArrows()
        }.bind(this)
      )

      this.galleryConstructor.listen(
        'beforeChange',
        function () {
          $('.pswp__caption__center').hide()
        }.bind(this)
      )

      this.galleryConstructor.listen(
        'afterChange',
        function () {
          // this.handleCaption()
          this.handleArrows()
          $('.pswp__caption__center').fadeIn('slow')
        }.bind(this)
      )

      $(window).on(
        'load resize',
        function () {
          this.handleIconSize()
          // this.handleCaption()
          this.handleArrows()
        }.bind(this)
      )
      $('.pswp__caption__center').hide()
      $('.pswp__caption__center').fadeIn('slow')

      this.disableBodyScroll(this.galleryConstructor)
    }.bind(this)

    let _this = this
    $(`.my-gallery-${variable} a`).on('click', function (e) {
      e.preventDefault()

      let template = $('#image-gallery-opened').html()
      let templateScript = Handlebars.compile(template)
      let html = templateScript({ images: gallery[variable] })
      $('.pswp__gallery').html(html)

      openPhotoSwipe()
      _this.galleryConstructor.goTo($(this).data('slide'))
    })
  }

  setListeners() {
    let _this = this
    $('.pswp__gallery').on('click', '.pswp__preview a', function (e) {
      e.preventDefault()
      let slideNumber = $(this).data('slide')
      _this.galleryConstructor.goTo(slideNumber)
      if ($(window).width() < 992) {
        $('.pswp').toggleClass('active-gallery')
        // _this.handleCaption()
        _this.handleArrows()
      }
    })
    $('.ico--f-grid').on('click', () => {
      $('.pswp__caption').css('opacity', '0')
      $('.pswp__caption__center').hide()
      $('.pswp__button--arrow--right i').hide()
      $('.pswp')
        .toggleClass('active-gallery')
        .on(
          'transitionend webkitTransitionEnd oTransitionEnd',
          function () {
            // this.handleCaption()
            this.handleArrows()
            $('.pswp__caption').css('opacity', '1')
            $('.pswp__caption__center').fadeIn('slow').delay(600)
            $('.pswp__button--arrow--right i').fadeIn('slow').delay(600)
          }.bind(this)
        )
    })
    $('.close, .pswp__bg')
      .not('.pswp__img')
      .on('click', () => {
        this.enableBodyScroll(this.galleryConstructor)
        this.galleryConstructor.close()
      })
  }

  createPhotoSwipeHTML(variable) {
    var layout = $(`.my-gallery-${variable}`).data('layout')
    let photoJSON
    if (layout.includes('hero')) photoJSON = gallery[variable].slice(0, 5)
    else photoJSON = gallery[variable].slice(0, 6)

    let template = $('#' + layout).html()
    let templateScript = Handlebars.compile(template)
    let html = templateScript({ images: photoJSON })

    $(`.my-gallery-${variable}`).append(html)
    if (layout.includes('hero')) {
      if (gallery[variable].length > this.templateHeroMax) {
        $(`.my-gallery-${variable} figure:nth-of-type(` + this.templateHeroMax + `) a`).addClass(
          'flex flex--center'
        )
        $(`.my-gallery-${variable} figure:nth-of-type(` + this.templateHeroMax + `) a`).append(
          '<span class="gallery__number">+' +
            (gallery[variable].length - this.templateHeroMax) +
            '</span>'
        )
        $(`.my-gallery-${variable} figure:nth-of-type(` + this.templateHeroMax + `)`)
          .find('img')
          .addClass('gallery__image preview')
      }
    } else {
      if (gallery[variable].length > this.templateDefaultMax) {
        $(`.my-gallery-${variable} figure:nth-of-type(` + this.templateDefaultMax + `) a`).addClass(
          'flex flex--center'
        )
        $(`.my-gallery-${variable} figure:nth-of-type(` + this.templateDefaultMax + `) a`).append(
          '<span class="gallery__number">+' +
            (gallery[variable].length - this.templateDefaultMax) +
            '</span>'
        )
        $(`.my-gallery-${variable} figure:nth-of-type(` + this.templateDefaultMax + `)`)
          .find('img')
          .addClass('gallery__image preview')
      }
    }
  }

  // Utilities

  handleCaption() {
    var top =
      $(this.galleryConstructor.currItem.container).find('img.pswp__img').outerHeight() +
      $(this.galleryConstructor.currItem.container).find('img.pswp__img').position().top
    $('.pswp__caption').not('.pswp__caption--fake').css('top', top)
  }

  handleArrows() {
    var leftArrowPosition = $('.pswp__counter').position().left
    var rightArrowPosition = $('.close').position().left
    $('.pswp__button--arrow--left').css('left', leftArrowPosition)
    $('.pswp__button--arrow--right').css('left', rightArrowPosition)
  }

  handleIconSize() {
    if (store.cache.$window.width() > 992) {
      $('.pswp__button--arrow--right i').removeClass('ico--lg ico--f-chevron-r-s')
      $('.pswp__button--arrow--left i').removeClass('ico--lg ico--f-chevron-l-s')
      $('.pswp__button--arrow--right i').addClass('ico--md ico--f-chevron-r-l')
      $('.pswp__button--arrow--left i').addClass('ico--md ico--f-chevron-l-l')
    } else if (store.cache.$window.width() < 992) {
      $('.pswp__button--arrow--right i').removeClass('ico--md ico--f-chevron-r-l')
      $('.pswp__button--arrow--left i').removeClass('ico--md ico--f-chevron-l-l')
      $('.pswp__button--arrow--right i').addClass('ico--lg ico--f-chevron-r-s')
      $('.pswp__button--arrow--left i').addClass('ico--lg ico--f-chevron-l-s')
    }
  }
}
