import { store } from '../../store/store.js';
import anime from 'animejs/lib/anime.es.js';

export default class SupportAnime{

    //----------------------
    // Initialise functions
    //----------------------

	constructor(){
        this.animateLargeCampaign();
        this.adhocAnimate();
	}

    //------------------------------------
    // Handle animation for functionality
    //------------------------------------

    animateLargeCampaign(){
        $('#large-campaigns').hover(function(){
            anime ({
                targets: '.flag',
                translateY: -10,
                scale: 1.1,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 80, 15, 0)',
                duration: 1000
            });
            anime ({
                targets: '.mountain',
                scale: 1.1,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 80, 15, 0)',
                duration: 1000
            });
        }, function(){
            anime ({
                targets: '.flag',
                translateY: 0,
                scale: 1,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 80, 15, 0)',
                duration: 1000
            });
            anime ({
                targets: '.mountain',
                translateY: 0,
                scale: 1,
                easing: 'easeInOutExpo',
                easing: 'spring(2, 80, 15, 0)',
                duration: 1000
            });
        })
    }

    adhocAnimate(){
        let shapes = [
            {
                d: "M48.5,82.1h-7V53.4c0-1.9,1.6-3.5,3.5-3.5h0c1.9,0,3.5,1.6,3.5,3.5V82.1z"
            },
            {
                d: "M48.5,82.1h-7V28.2c0-1.9,1.6-3.5,3.5-3.5h0c1.9,0,3.5,1.6,3.5,3.5V82.1z"
            }
        ]

        $('#adhoc-requests').hover(function(){
            anime ({
                targets: '.morph',
                d: [

                    {value: shapes[1].d}
                ],
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 20, 5)',
                duration: 1000,
            });
        }, function(){
            anime ({
                targets: '.morph',
                d: [

                    {value: shapes[0].d}
                ],
                easing: 'easeInOutExpo',
                easing: 'spring(2, 90, 20, 5)',
                duration: 1000,
            });
        })
    }
}
