/* eslint-disable */

/**
* Import Stylesheet
*/
import '../scss/main.scss';
import './base/icon.font.js';

/**
* Import Components
*/
import InitPortalTab from './components/portal/portal-tab.js';
import InitStickyTabs from './components/portal/tab.js';
import InitPortalNav from './components/portal/sidenav.js';

//Animatation
import BaseAnime from './components/portal/base.js';
import GridAnime from './components/portal/grid.js';
import HomepageAnime from './components/portal/homepage.js';
import IconographyAnime from './components/portal/iconography.js';
import SupportAnime from './components/portal/support.js';

// Forms
import InitFormCounter from './components/forms/form-counter.js';
import InitFormDropdown from './components/forms/form-dropdown.js';
import InitFormInput from './components/forms/form-input.js';

import InitForms from './components/forms/form.js';

/**
* Init - initalises all project classes
*/
class Init{

	constructor(){
		//For Dev only
		window.$ = window.jQuery = require("jquery");

		//Base Initialisers
        if ($('main.portal').length > 0) {
			new InitPortalTab();
			new InitPortalNav();
		}

		new InitStickyTabs();
		

        if ($('form').length > 0) {
            new InitFormInput()
        	new InitFormDropdown()
        	new InitFormCounter()
        	new InitForms()
        }

        //Animation
        new BaseAnime();
        new GridAnime();
        new HomepageAnime();
        new IconographyAnime();
        new SupportAnime();
	}

}
new Init();
